import React from "react";
import "./LoginPage.scss";

const RegisterPage = () => {
  return (
    <div className="login-page">
      <h1 className="login-page__title">Đăng ký thành viên</h1>
      <div className="login-page__content">
        <div className="login-page__form">
          <h2 className="login-page__form-title">Thông tin tài khoản</h2>

          <form>
            <div className="form-group">
              <input type="ho" id="ho" placeholder="Họ" />
            </div>
            <div className="form-group">
              <input type="ten" id="ten" placeholder="Tên" />
            </div>
            <div className="form-group">
              <input type="phone" id="phone" placeholder="Điện thoại" />
            </div>
            <div className="form-group">
              <input type="email" id="email" placeholder="Email" />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password"
                placeholder="Mật khẩu"
              />
            </div>
            <div className="form-group">
              <input
                type="password2"
                id="password2"
                placeholder="Xác nhận mật khẩu"
              />
            </div>
            <p className="login-page__form-description">
              Mật khẩu phải có ít nhất 1 ký tự viết hoa và một ký tự đặc biết
              !@#$%^&*()
            </p>
            <div className="form-options-register">
              <label>
                <input type="checkbox" /> Tôi chấp nhận{" "}
                <a href="#"> chính sách bán hàng & chính sách bảo mật</a> của Là
                Nhà Decor
              </label>
            </div>
            <button type="submit" className="btn btn-primary ">
              Đăng nhập
            </button>
          </form>
        </div>

        <div className="login-page__register">
          <h2>Dành riêng cho hội viên</h2>
          <p>
            Trải nghiệm những quyền lợi riêng biệt của Là Nhà Decor Membership
          </p>
          <ul className="login-page__register-benefits">
            <li className="login-page__register-benefit">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>{" "}
              Tích điểm cho mỗi đơn hàng
            </li>
            <li className="login-page__register-benefit">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Voucher tháng sinh nhật
            </li>
            <li className="login-page__register-benefit">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Chương trình khuyến mãi đặc biệt cho Member
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
