import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ModalViewFull.scss";

export default function ModalViewFull({ featureDetail, isMobile, close }) {
  const swiperRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;

      setCurrentSlide(swiper.activeIndex + 1);
      setTotalSlides(swiper.slides.length);
    }
  }, [featureDetail]);

  return (
    <main className="modal-full">
      <div className="modal-full__header">
        <button
          className="modal-full__header__close"
          onClick={() => {
            close();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.39994 18.6542L5.34619 17.6004L10.9462 12.0004L5.34619 6.40043L6.39994 5.34668L11.9999 10.9467L17.5999 5.34668L18.6537 6.40043L13.0537 12.0004L18.6537 17.6004L17.5999 18.6542L11.9999 13.0542L6.39994 18.6542Z"
              fill="#121212"
            />
          </svg>
        </button>
      </div>

      <div className="modal-full__body">
        <div className="modal-full__body__slide">
          <Swiper
            ref={swiperRef}
            className="inspire__swiper"
            slidesPerView={1}
            spaceBetween={10}
            navigation={true}
            speed={1000}
            pagination={{
              type: "bullets",
              dynamicBullets: true,
              dynamicMainBullets: isMobile ? 3 : 5,
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
          >
            {featureDetail?.images?.map((imgUrl, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img src={imgUrl} alt="feature detail" />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <span className="modal-full__body__slide__number">
            {currentSlide}/{totalSlides}
          </span>
        </div>
      </div>
    </main>
  );
}
