import { useNavigate } from "react-router-dom";
import "./BlogCard.scss";

const BlogCard = ({ blog }) => {
    const navigate = useNavigate();
    return (<div className="card--blog" onClick={(e) => {
        e.stopPropagation();
        navigate(`/tin-tuc/${blog?._id}`);
    }}>
        <img src={blog?.img} alt="" />
        <div className="card--blog--content">
            <div className="title">{blog?.title}</div>
            <div className="description">{blog?.summary}</div>
        </div>
    </div>);
}

export default BlogCard;