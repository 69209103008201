import { useEffect, useState } from "react";
import ButtonOutlineSmall from "../../components/Button/ButtonOutlineSmall";
import ButtonSolidSmall from "../../components/Button/ButtonSolidSmall";
import ButtonDropdown from "../../components/ButtonDropdown/ButtonDropdown";
import ChipSuggest32 from "../../components/Chip/ChipSuggest32";
import { useSelector } from "react-redux";

const ButtonDropdownStyle = ({
  id,
  styleFilter,
  setStyleFilter,
  showButton,
}) => {
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const [tempStyle, setStyle] = useState([]);

  const closeModal = () => {
    const dropdown = document.getElementById(id + "Dropdown-Container");

    if (dropdown) {
      dropdown.classList.remove("show");
      dropdown.classList.add("hidden");
    }
  };

  useEffect(() => {
    setStyle([...styleFilter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleFilter]);

  return (
    <ButtonDropdown
      isHidden={true}
      title={"Phong cách"}
      badge={styleFilter?.length ? styleFilter?.length : undefined}
      id={id}
      container={
        <div className="material--filter--container">
          <div className="material--filter--container-content">
            {dataProductCategory?.styleFilter?.map((style, i) => (
              <ChipSuggest32
                key={i}
                title={style?.title}
                onClick={() => {
                  const newMaterial = showButton
                    ? tempStyle
                    : styleFilter;
                  const index = newMaterial?.findIndex(
                    (id) => id === style._id
                  );
                  if (index > -1) {
                    newMaterial.splice(index, 1);
                  } else newMaterial.push(style?._id);
                  if (showButton) setStyle([...newMaterial]);
                  else setStyleFilter([...newMaterial]);
                }}
                isSelected={tempStyle?.includes(style?._id)}
              />
            ))}
          </div>
          {showButton && (
            <div className="material--filter--container-button">
              <ButtonOutlineSmall
                title={"Đóng"}
                type={"button"}
                onClick={() => {
                  closeModal();
                  setStyleFilter(undefined);
                }}
              />
              <ButtonSolidSmall
                title={"Áp dụng"}
                type={"button"}
                onClick={() => {
                  closeModal();
                  setStyleFilter(tempStyle);
                }}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default ButtonDropdownStyle;
