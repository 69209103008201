import { useEffect } from "react";
import ICError from "../../assets/icons/ICError";
import ICExpandMore from "../../assets/icons/ICExpandMore";

const InputDropdown = ({name, id, posIcon, placeholder, value, setValue, readOnly, options, required}) => {

  function addBorder() {
    const selectComponent = document.getElementById(id);
    if(selectComponent.value!==""){
      selectComponent.classList.add("selected"); // Add border when a value is selected
      setValue(selectComponent.value);
    } else {
      selectComponent.classList.remove("selected"); // Remove border if no value is selected
      setValue("")
    }
  }
  return ( <div className="input--container">
        {posIcon && <span className="pos--icon">{posIcon}</span>}
  
        {/* <input
          type="text"
          placeholder={placeholder??"Placeholder"}
          value={value}
          onChange={(e) => {if(setValue)setValue(e.target.value)}}
          readOnly={readOnly}
        />
        <span className="icon--expand" onClick={() => setValue("")}>
          <ICExpandMore width={16} height={16} />
        </span>
        <span className="icon--error" onClick={() => setValue("")}>
          <ICError width={16} height={16} />
        </span> */}
        <select name={name} id={id} value={value} onChange={addBorder} defaultValue={""} required={required}>
          {placeholder&& <option value="" disabled hidden >{placeholder}</option>}
          {options?.map((option, i)=><option value={option.codename} key={id+i+option.code}>{option.name}</option>)}
        </select>
      </div>);
}
 
export default InputDropdown;