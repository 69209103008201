import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataProvinces:[],
  loading: false,
};

export const Provinces = createSlice({
  name: "provinces",
  initialState,
  reducers: {
    setProvinces: (state, action) => {
      state.dataProvinces=(action?.payload);
    },
    
  },
});

export const {
  setProvinces
} = Provinces.actions;

export default Provinces.reducer;

