import { useEffect, useState } from "react";
import ButtonOutlineSmall from "../../components/Button/ButtonOutlineSmall";
import ButtonSolidSmall from "../../components/Button/ButtonSolidSmall";
import ButtonDropdown from "../../components/ButtonDropdown/ButtonDropdown";
import { useSelector } from "react-redux";
import ChipSuggestIcon32 from "../../components/Chip/ChipSuggestIcon32";

const ButtonDropdownPrice = ({
  id,
  colorFilter,
  setColorFilter,
  showButton,
}) => {
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const [tempColor, setTempColor] = useState([]);

  const closeModal = () => {
    const dropdown = document.getElementById(id + "Dropdown-Container");

    if (dropdown) {
      dropdown.classList.remove("show");
      dropdown.classList.add("hidden");
    }
  };

  useEffect(() => {
    setTempColor([...colorFilter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorFilter]);

  return (
    <ButtonDropdown
      isHidden={true}
      title={"Màu sắc"}
      badge={colorFilter.length ? colorFilter.length : undefined}
      id={id}
      container={
        <div className="color--filter--container">
          <div className="color--filter--container-content">
            {dataProductCategory?.similarColorFilter?.map((color, i) => (
              <ChipSuggestIcon32
                isSelected={
                  showButton
                    ? tempColor?.includes(color?._id)
                    : colorFilter?.includes(color?._id)
                }
                key={i}
                title={color?.title}
                item={
                  <img
                    src={color?.icon}
                    alt="similar color"
                    style={{ verticalAlign: "top" }}
                  />
                }
                onClick={(e) => {
                  const newColor = showButton ? tempColor : colorFilter;
                  const index = newColor.findIndex((id) => id === color?._id);
                  if (index > -1) {
                    newColor.splice(index, 1);
                  } else newColor.push(color?._id);
                  if (showButton) setTempColor([...newColor]);
                  else setColorFilter([...newColor]);
                }}
              />
            ))}
          </div>
          {showButton && (
            <div className="color--filter--container-button">
              <ButtonOutlineSmall
                title={"Đóng"}
                type={"button"}
                onClick={() => {
                  closeModal();
                  setColorFilter(undefined);
                }}
              />
              <ButtonSolidSmall
                title={"Áp dụng"}
                type={"button"}
                onClick={() => {
                  closeModal();
                  setColorFilter(tempColor);
                }}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default ButtonDropdownPrice;
