export function formatToVND(amount) {
  try {
    if (amount) {
      return new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
      }).format(amount).replaceAll(".", ",");
    }
  } catch (e) {
    console.log(e);
    return "--";
  }
}
