import callApi from "../../utils/callApi";
import { SectionPath } from "../../utils/enum";
import { setError, setFAQSession, setLoading } from "../reducers/faq.reducers";

export const getFAQAction = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true)); // Bắt đầu loading
      const res = await callApi(SectionPath.HERO_FAQ, "GET", null);

      // Cập nhật dữ liệu section
      dispatch(setFAQSession(res.data.data));
      dispatch(setLoading(false)); // Kết thúc loading
    } catch (err) {
      dispatch(setError(err.message));
      dispatch(setLoading(false));
    }
  };
};
