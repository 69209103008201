const InputCheckbox = ({id, value, setValue, title, disabled}) => {
    return ( <div className="input--checkbox">
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => {
            setValue(e.target.checked);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={id}
          disabled={disabled}
          style={{ cursor: "pointer" }}
        />
        {title && (
          <label htmlFor={id} className="input--title">
            {title}
          </label>
        )}
      </div> );
}
 
export default InputCheckbox;