import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CardExpand from "../../components/CardExpand/CardExpand";
import ProductCard from "../../components/ProductCard/ProductCard";
import { getElectricSofaAction } from "../../store/actions/electricSofa.action";
import { getDataSection } from "../../store/actions/homePage.action";
import { getMechanicalSofaAction } from "../../store/actions/mechanicalSofa.action";
import { ProductPath, SectionPath, TypeProduct } from "../../utils/enum";
import "./HomePage.scss";
import callApi from "../../utils/callApi";
import { stringToUrl } from "../../utils/url.utils";
import ReactPixel from "react-facebook-pixel";

export default function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataHomePage } = useSelector((state) => state.dataHomePage);
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const videoRef = useRef(null);
  const hideRef = useRef(null);

  const [showContent, setShowContent] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 577);

  const [lstBestSeller, setLstBestSeller] = useState([]);
  const [lstProdFeature, setLstProdFeature] = useState([]);

  const getLstBestSeller = async () => {
    try {
      const res = await callApi(ProductPath.PROD_BEST_SELL, "GET");

      if (res?.data?.data) {
        setLstBestSeller(res.data.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const getLstProdFeatured = async () => {
    try {
      const res = await callApi(ProductPath.PROD_FEATURED, "GET");

      if (res?.data?.data) {
        setLstProdFeature(res.data.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 576);
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      setIsPlaying(!isPlaying);
    }
  };

  const sectionRefs = useRef([]);

  useEffect(() => {
    document.title = "Là Nhà Decor";

    dispatch(getDataSection(SectionPath.HERO__IIEMS));
    dispatch(getDataSection(SectionPath.CATEGORIES));
    dispatch(getDataSection(SectionPath.FEATURED_PRODUCTS));
    dispatch(getDataSection(SectionPath.KEY_FEATURE));
    dispatch(getDataSection(SectionPath.FEATURE_ITEMS));
    dispatch(getDataSection(SectionPath.KEY_DETAILS));
    dispatch(getDataSection(SectionPath.DISCOUNT));
    dispatch(getDataSection(SectionPath.DISCOUNT_2));
    dispatch(getDataSection(SectionPath.BEST_SELLING_PRODUCTS));
    dispatch(getDataSection(SectionPath.INTRO_SHOWROOM));
    dispatch(getDataSection(SectionPath.VIDEO));
    dispatch(getDataSection(SectionPath.INSPIRE));
    dispatch(getDataSection(SectionPath.HERO_ABOUT_US));

    getLstBestSeller();
    getLstProdFeatured();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="homepage">
      {dataHomePage?.hero__items &&
      dataHomePage?.hero__items?.items?.length > 0 ? (
        <section
          id="hero__items"
          ref={(el) => (sectionRefs.current[0] = el)}
          className="hero-section"
          onContextMenu={(e) => e.preventDefault()}
        >
          <Swiper
            lazy="true"
            className="hero-section__swiper"
            loop={true}
            speed={1000}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {dataHomePage?.hero__items?.items?.map((hItem) => {
              return (
                <SwiperSlide key={hItem._id}>
                  <div className="overlay-img"></div>

                  <img
                    className="ratio ratio-21x9"
                    src={
                      isMobile
                        ? hItem.attachments_mobile[0]
                        : hItem.attachments[0]
                    }
                    alt="hero item"
                    onContextMenu={(e) => e.preventDefault()}
                  />

                  <div className="hero-section__swiper__content">
                    <h3
                      className="hero-section__swiper__content__title"
                      dangerouslySetInnerHTML={{
                        __html: hItem.title,
                      }}
                    />

                    <p
                      className="hero-section__swiper__content__text"
                      dangerouslySetInnerHTML={{
                        __html: hItem.content,
                      }}
                    />

                    <button
                      className="btn-main-not-icon"
                      style={{
                        boxShadow:
                          "0px -1px 4px 0px rgba(58, 56, 49, 0.05), 0px 4px 10px 0px rgba(58, 56, 49, 0.08);",
                      }}
                      onClick={() => {
                        const allProduct =
                          dataProductCategory?.categoryFilter?.find(
                            (category) =>
                              category?.title?.toLowerCase().includes("tất cả")
                          );
                        console.log(allProduct);
                        navigate(`/sofa/${stringToUrl(allProduct?.title)}`);
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: hItem.content_button,
                        }}
                      />
                    </button>
                  </div>

                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      ) : (
        <section>
          <Skeleton height={800} />
        </section>
      )}

      <section className="categories">
        <div className="categories__top">
          <h5
            className="categories__top__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.categories?.sub_title,
            }}
          />

          <h3
            className="categories__top__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.categories?.title,
            }}
          />
        </div>

        <div className="categories__swiper">
          <Swiper
            // freeMode={isMobile ? false : true}
            lazy="true"
            slidesPerView={"auto"}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            navigation={true}
            speed={1000}
            modules={[FreeMode, Navigation, Pagination, Scrollbar]}
          >
            {dataHomePage?.categories?.sub_sections?.map((sItem, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  onClick={() => navigate(sItem?.link_button)}
                >
                  <img
                    style={{ minHeight: 300 }}
                    src={sItem?.attachments && sItem?.attachments[0]}
                    alt="category"
                    onContextMenu={(e) => e.preventDefault()}
                  />

                  <p
                    className="categories__swiper__name"
                    dangerouslySetInnerHTML={{
                      __html: sItem?.title,
                    }}
                  />

                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>

      <section className="featured-products">
        <div className="featured-products__top">
          <h5
            className="featured-products__top__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.featured_products?.sub_title,
            }}
          />

          <h3
            className="featured-products__top__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.featured_products?.title,
            }}
          />
        </div>

        <div className="featured-products__swiper">
          <Swiper
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            slidesPerView={"auto"}
            navigation={true}
            speed={1000}
            modules={[FreeMode, Navigation, Pagination, Scrollbar]}
            breakpoints={{
              576: {
                slidesPerView: "auto",
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 3,
              },
              1920: {
                slidesPerView: 4,
              },
            }}
          >
            {lstProdFeature?.map((mSofa) => {
              return (
                <SwiperSlide key={mSofa._id}>
                  <ProductCard allowTouchMove={false} product={mSofa} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="col-12 d-flex flex-row justify-content-center">
            <button
              className="btn-white-not-icon"
              onClick={() => {
                navigate(dataHomePage?.featured_products?.link_button);
                ReactPixel.track("ViewContent", {
                  content_name: "All Products",
                  content_type: "product_group",
                });
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: dataHomePage?.featured_products?.content_button,
                }}
              />
            </button>
          </div>
        </div>
      </section>

      <section className="key-details">
        <div className="key-details__relative">
          <div className="key-details__text">
            <h5
              className="key-details__text__heading"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_details?.sub_title,
              }}
            />

            <h3
              className="key-details__text__title"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_details?.title,
              }}
            />

            <p
              className="key-details__text__content"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_details?.content,
              }}
            ></p>
          </div>

          {!isMobile && (
            <div className="key-details__button">
              <button
                className="btn-white-not-icon"
                onClick={() => navigate(dataHomePage?.key_details?.link_button)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: dataHomePage?.key_details?.content_button,
                  }}
                />
              </button>
            </div>
          )}

          <div className="key-details-slider">
            <Swiper
              slidesPerView="auto"
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              navigation={false}
              speed={1000}
              modules={[Scrollbar]}
            >
              {dataHomePage?.key_details?.sub_sections?.map((ssItem) => {
                return (
                  <SwiperSlide key={ssItem._id}>
                    <div
                      key={ssItem._id}
                      className="key-details-container__card"
                    >
                      <div className="key-details-container__card__header">
                        <img
                          src={ssItem.icon_title}
                          alt="sub section"
                          onContextMenu={(e) => e.preventDefault()}
                        />

                        <span
                          dangerouslySetInnerHTML={{
                            __html: ssItem.title,
                          }}
                        />
                      </div>

                      <video
                        playsInline
                        autoPlay
                        loop
                        muted
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        <source src={ssItem.attachments[0]} type="video/mp4" />
                      </video>

                      <p
                        className="key-details-container__card__content"
                        dangerouslySetInnerHTML={{
                          __html: ssItem.content,
                        }}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="key-details-container">
            {dataHomePage?.key_details?.sub_sections?.map((ssItem) => {
              return (
                <div key={ssItem._id} className="key-details-container__card">
                  <div className="key-details-container__card__header">
                    <img
                      src={ssItem.icon_title}
                      alt="key detail"
                      onContextMenu={(e) => e.preventDefault()}
                    />

                    <span
                      dangerouslySetInnerHTML={{
                        __html: ssItem.title,
                      }}
                    />
                  </div>

                  <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <source src={ssItem.attachments[0]} type="video/mp4" />
                  </video>

                  <p
                    className="key-details-container__card__content"
                    dangerouslySetInnerHTML={{
                      __html: ssItem.content,
                    }}
                  />
                </div>
              );
            })}
          </div>

          {isMobile && (
            <div className="key-details__button">
              <button
                className="btn-white-not-icon"
                onClick={() => navigate(dataHomePage?.key_details?.link_button)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: dataHomePage?.key_details?.content_button,
                  }}
                />
              </button>
            </div>
          )}
        </div>

        <img
          className="key-details__image"
          src={dataHomePage?.key_details?.attachments[0]}
          alt="key detail"
          onContextMenu={(e) => e.preventDefault()}
        />
      </section>

      <section className="discount">
        {isMobile ? (
          <img
            alt="discount"
            src={
              dataHomePage?.discount?.attachments_mobile &&
              dataHomePage?.discount?.attachments_mobile[0]
            }
            className="w-100"
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : (
          <img
            alt="discount"
            src={
              dataHomePage?.discount?.attachments &&
              dataHomePage?.discount?.attachments[0]
            }
            className="w-100"
            onContextMenu={(e) => e.preventDefault()}
          />
        )}
      </section>

      <section className="product-list-section">
        <div className="categories__top">
          <h5
            className="categories__top__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.best_selling_products?.sub_title,
            }}
          />

          <h3
            className="categories__top__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.best_selling_products?.title,
            }}
          />
        </div>

        <div className="product-container">
          {lstBestSeller?.slice(0, 8)?.map((prod) => {
            return (
              <div key={prod._id} className="product-container__item">
                <ProductCard product={prod} />
              </div>
            );
          })}
        </div>

        <div className="col-12 d-flex flex-row justify-content-center">
          <button
            className="btn-white-not-icon"
            onClick={() =>
              navigate(dataHomePage?.best_selling_products?.link_button)
            }
          >
            <span
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.best_selling_products?.content_button,
              }}
            />
          </button>
        </div>
      </section>

      <section className="discount">
        <img
          alt="discount"
          src={
            dataHomePage?.discount_2?.attachments &&
            dataHomePage?.discount_2?.attachments[0]
          }
          className="w-100"
          onContextMenu={(e) => e.preventDefault()}
        />
      </section>

      <section
        id="key_feature"
        ref={(el) => (sectionRefs.current[3] = el)}
        className="key-features"
      >
        <div className="key-features__show">
          <div className="key-features__show__left">
            <h5
              className="key-features__show__left__heading"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_feature?.sub_title,
              }}
            />

            <h3
              className="key-features__show__left__title"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_feature?.title,
              }}
            />

            <p
              className="key-features__show__left__content"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_feature?.content,
              }}
            />

            <button
              className="btn-transprent"
              onClick={() => {
                setShowContent(!showContent);
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: dataHomePage?.key_feature?.content_button,
                }}
              />
            </button>
          </div>

          <div className="key-features__show__right">
            <img
              src={
                dataHomePage?.key_feature?.attachments &&
                dataHomePage?.key_feature?.attachments[0]
              }
              alt="key feature"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </div>

        <div
          ref={hideRef}
          className={`key-features__hide ${
            showContent ? "key-features__hide--expanded" : ""
          }`}
        >
          <div className="line"></div>

          {isMobile ? (
            <div className="key-features__hide__main">
              <div className="slider-scroll-mobile">
                <Swiper
                  style={{ height: "430px" }}
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  scrollbar={{ draggable: true }}
                  navigation={true}
                  speed={1000}
                  modules={[FreeMode, Navigation, Pagination, Scrollbar]}
                >
                  {dataHomePage?.feature__items?.items?.map((fItem, idx) => {
                    return (
                      <SwiperSlide key={fItem._id}>
                        <CardExpand data={fItem} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          ) : (
            <div className="key-features__hide__main">
              {dataHomePage?.feature__items?.items?.map((fItem) => {
                return (
                  <div
                    key={fItem._id}
                    className="key-features__hide__main__item"
                  >
                    <CardExpand data={fItem} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>

      <section className="intro-showroom">
        <img
          className="intro-showroom__image"
          src={
            dataHomePage?.intro_showroom?.attachments &&
            dataHomePage?.intro_showroom?.attachments[0]
          }
          alt="showroom"
          onContextMenu={(e) => e.preventDefault()}
        />

        <div className="intro-showroom__content">
          <h3
            className="intro-showroom__content__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.intro_showroom?.title,
            }}
          />

          <p
            className="intro-showroom__content__text"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.intro_showroom?.content,
            }}
          />

          <button
            className="btn-main-not-icon"
            onClick={() => window.open("https://zalo.me/0941446571", "_blank")}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.intro_showroom?.content_button,
              }}
            />
          </button>
        </div>
      </section>

      <section
        id="video"
        ref={(el) => (sectionRefs.current[2] = el)}
        className="video-section"
      >
        {dataHomePage?.video?.attachments && (
          <video
            ref={videoRef}
            playsInline
            autoPlay
            loop
            muted
            onContextMenu={(e) => e.preventDefault()}
          >
            <source src={dataHomePage.video.attachments[0]} type="video/mp4" />
          </video>
        )}

        <button
          className="btn-play"
          onClick={() => {
            togglePlayPause();
          }}
        >
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14 18.5V5.5H17.75V18.5H14ZM6.25 18.5V5.5H10V18.5H6.25Z"
                fill="#121212"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.5 18.0961V5.90405L18.0768 12.0001L8.5 18.0961Z"
                fill="#121212"
              />
            </svg>
          )}
        </button>
      </section>

      <section className="inspire">
        <div className="inspire__top">
          <h5
            className="inspire__top__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.inspire?.sub_title,
            }}
          />

          <h3
            className="inspire__top__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.inspire?.title,
            }}
          />

          <p
            className="inspire__top__content"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.inspire?.content,
            }}
          />
        </div>

        <div>
          <Swiper
            className="inspire__swiper"
            freeMode={isMobile ? false : true}
            slidesPerView={"auto"}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            navigation={true}
            speed={1000}
            modules={[FreeMode, Navigation, Pagination, Scrollbar]}
          >
            {dataHomePage?.inspire?.attachments?.map((sItem, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img
                    src={sItem}
                    alt="inspire"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </div>
  );
}
