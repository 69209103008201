import emailjs from '@emailjs/browser';

export class EmailJS {
   sendEmail = ({orderId,title,subtitle,user_email,user_name,user_phone,user_address,date_receive,list_product,method,card,predict,fee_shipping,discount,total,paid,remained_money,note,fromName,order_period}) => {
    const templateParams = {
      orderId,title,subtitle,user_email,user_name,user_phone,user_address,date_receive,list_product,method,card,predict,fee_shipping,discount,total,paid,remained_money,note,fromName,order_period
      };
    
      emailjs
        .send(
            process.env.REACT_APP_MAIL_SERVICE_ID, // Thay bằng Service ID từ EmailJS
          process.env.REACT_APP_MAIL_TEMPLATE, // Thay bằng Template ID từ EmailJS
          templateParams,
          process.env.REACT_APP_MAIL_PUBLIC_KEY,     // Thay bằng User ID từ EmailJS
        )
        .then(
          (response) => {
            console.log('Email sent successfully!', response.status, response.text);
          },
          (error) => {
            console.error('Failed to send email.', error);
          }
        );
  };
};