import {
  setProvinces
} from "../reducers/provinces.reducers";
import axios from "axios";

export const getProvinces = () => {
  return async (dispatch) => {
    try {
      try {
        await axios({
          method: "GET",
          url: `https://provinces.open-api.vn/api/?depth=3`,
        }).then(async (res)=> {
          await dispatch(setProvinces(res?.data))
        });
      } catch (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
        } else {
        }
      }

      
    } catch (err) {
      console.log(err);
    }
  };
};
