require("../enum.js");
const createInvoice = require("./CreateInvoice.js");
const createInvoiceInstallment = require("./CreateInvoiceInstallment.js");

async function makeInvoice({amount, orderInfo, vpcMerTxnRef, cardList, customerId, customerEmail, customerPhone, deposit}) {
  const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  let merchantId = process.env.REACT_APP_MERCHANT_PAYNOW_ID;
  let merchantAccessCode = process.env.REACT_APP_MERCHANT_PAYNOW_ACCESS_CODE;
  let merchantHashCode = process.env.REACT_APP_MERCHANT_PAYNOW_HASH_CODE;
  let againLink = baseURL+"/thanh-toan";
  let returnURL = baseURL+"/thanh-toan/trang-thai";
  await createInvoice.createInvoice({merchantId, merchantAccessCode, merchantHashCode, vpcMerTxnRef, returnURL, againLink, amount, orderInfo, cardList, customerId, customerEmail, customerPhone});
}

function makeInvoiceInstallmentAtOnePaySite({amount, orderInfo, vpcMerTxnRef, cardList, customerId, customerEmail, customerPhone}) {
  const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  let merchantId = process.env.REACT_APP_MERCHANT_INSTALLMENT_ID;
  let merchantAccessCode = process.env.REACT_APP_MERCHANT_INSTALLMENT_ACCESS_CODE;
  let merchantHashCode = process.env.REACT_APP_MERCHANT_INSTALLMENT_HASH_CODE;
  let againLink = baseURL+"/thanh-toan";
  let returnURL = baseURL+"/thanh-toan/trang-thai";
  createInvoiceInstallment.createInvoiceInstallment(
    {merchantId, merchantAccessCode, merchantHashCode, vpcMerTxnRef, returnURL, againLink, amount, orderInfo, cardList, customerId, customerEmail, customerPhone}
  );
}


module.exports={
  makeInvoice,
  makeInvoiceInstallmentAtOnePaySite,
}