const util = require("./Util.js");

async function createInvoice({merchantId, merchantAccessCode, merchantHashCode, vpcMerTxnRef, returnURL, againLink, amount, orderInfo, cardList, customerId, customerEmail, customerPhone}) {
  let merchantParam = {
    vpc_Version: "2",
    vpc_Currency: "VND",
    vpc_Command: "pay",
    vpc_AccessCode: merchantAccessCode,
    vpc_Merchant: merchantId,
    vpc_Locale: "vn",
    vpc_ReturnURL: returnURL,
    vpc_MerchTxnRef: vpcMerTxnRef,
    vpc_OrderInfo: orderInfo,
    vpc_Amount: `${amount*100}`,
    vpc_TicketNo: await util.getIPAddress(),
    AgainLink: againLink,
    Title: "Thanh toán",
    vpc_Customer_Phone: customerPhone,
    vpc_Customer_Email: customerEmail,
    vpc_Customer_Id: customerId,
    vpc_CardList:cardList
  };
  let sortedParam = util.sortObj(merchantParam);
  let stringToHash = util.generateStringToHash(sortedParam);
  let secureHash = util.genSecureHash(stringToHash, merchantHashCode);
  merchantParam["vpc_SecureHash"] = secureHash;
  let urlRequest =
    process.env.REACT_APP_ONEPAY_URL + process.env.REACT_APP_URL_PREFIX + new URLSearchParams(merchantParam);
  await util.sendHttpsGet(urlRequest);
}

module.exports = {
  createInvoice,
};
