const ICBank = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width ?? undefined, height: height ?? undefined }}
      viewBox={`0 0 24 24`}
      fill="currentColor"
    >
      <path d="M7.15375 20.6537L2.5 15.9999L7.15375 11.3462L8.223 12.3999L5.373 15.2499H20.5V16.7499H5.373L8.223 19.5999L7.15375 20.6537ZM16.8462 12.6537L15.777 11.5999L18.627 8.74994H3.5V7.24994H18.627L15.777 4.39994L16.8462 3.34619L21.5 7.99994L16.8462 12.6537Z"/>
    </svg>
  );
};
export default ICBank;
