import callApi from "../../utils/callApi";
import { ProductPath } from "../../utils/enum";
import { updateRecentlyProduct, updateRecentlyProductId } from "../reducers/recentlyProduct.reducers";

export const fetchRecentlyProductsAction = (ids) => {
  return async (dispatch) => {
    try {
      const res = await callApi(ProductPath.PROD_RECENT, "POST", ids);

      await dispatch(updateRecentlyProduct(res.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateRecentlyProductsIdAction = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(updateRecentlyProductId(id));
    } catch (err) {
      console.log(err);
    }
  };
};
