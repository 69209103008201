import React from "react";
import "./CardExpand.scss";

export default function CardExpand({ data }) {
  return (
    <main className="cardExpand">
      <img
        className="cardExpand__image"
        src={data?.attachments && data?.attachments[0]}
        alt="hiden image"
      />

      <div className="cardExpand__text">
        <h5 className="cardExpand__text__title">
          {data?.title}
        </h5>

        <p
          className="cardExpand__text__content"
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        ></p>
      </div>
    </main>
  );
}
