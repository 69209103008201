import React from "react";
import "./AccountInfoPage.scss";
import { useNavigate } from "react-router-dom";

const AccountInfoPage = () => {
    const navigate = useNavigate();
  return (
    <div className="login-page">
      <h1 className="login-page__title">Hai Dang</h1>
      <div className="login-page__content">
      <div className="login-page__register">
          <h2>Cài đặt tài khoản</h2>
          <p>
          Các thông tin và  các thiết lập cho tài khoản của bạn 
          </p>
          
          <button className="btn btn-secondary" onClick={() => navigate("/dang-ky")}>Đăng xuất</button>
          <button className="btn btn-secondary" onClick={() => navigate("/dang-ky")}>Xóa tài khoản</button>
        </div>
        <div className="login-page__form">
          <h2 className="login-page__form-title">Đăng nhập tài khoản</h2>
          <p className="login-page__form-description">
            Đăng nhập để truy cập tài khoản của bạn
          </p>
          <form>
            <div className="form-group">
              {/* <label htmlFor="email">Địa chỉ email</label> */}
              <input type="email" id="email" placeholder="Nhập địa chỉ email" />
            </div>
            <div className="form-group">
              {/* <label htmlFor="password">Mật khẩu</label> */}
              <input
                type="password"
                id="password"
                placeholder="Nhập mật khẩu"
              />
            </div>
            <div className="form-options">
              <label>
                <input type="checkbox" /> Lưu đăng nhập
              </label>
              <a href="#">Quên mật khẩu?</a>
            </div>
            <p className="login-page__terms">
              Nhấn "Đăng nhập" đồng nghĩa với việc bạn đồng ý tuân theo{" "}
              <a href="#">Các điều khoản & điều kiện của Là Nhà Decor</a>
            </p>
            <button type="submit" className="btn btn-primary ">
              Đăng nhập
            </button>
          </form>
        </div>

        
      </div>
    </div>
  );
};

export default AccountInfoPage;
