import ICBank from "../../assets/icons/ICBank";
import ICCreditCard from "../../assets/icons/ICCreditCard";
import ICCreditCardClock from "../../assets/icons/ICCreditCardClock";
import ICQr from "../../assets/icons/ICQr";
import ICMomo from "../../assets/icons/ICMomo";
import "./Button.scss"
const ButtonRadio = ({id, name, posIcon, title, subtitle, valueTitle, titleSelected, handleChangeTitleSelected, requiredTitle, valueOption, requiredOption, handleChangeOption}) => {
    return ( <div className="button--radio--container" onClick={()=>{handleChangeTitleSelected(valueTitle)}}>
        <div className="button--radio--container--title">
            <input type="radio" required={requiredTitle} id={id} name={name} value={valueTitle} checked={titleSelected === valueTitle}
            onChange={(e)=>{
                handleChangeTitleSelected(e.target.value)
            }}/>
            <label htmlFor={id}><span>{posIcon}{title}</span>
            <span className="sub--title">{subtitle}</span></label>
        </div>
        {titleSelected === valueTitle && <div className="button--radio--container--body">
            <div className="button--radio--container--body--title">
                <input type="radio" required={requiredOption && titleSelected === valueTitle} id={"DOMESTIC"} name={valueTitle+"paymentMethod"} value={"DOMESTIC"} checked={"DOMESTIC" === valueOption}
                onChange={(e)=>{
                    handleChangeOption(e.target.value)
                }}/>
                <label htmlFor={"DOMESTIC"}><span>{<ICBank/>}<div style={{display:"inline", gap:"2px"}}>Thẻ nội địa ATM<span className="sub--title"></span></div></span></label>
            </div>
            <div className="button--radio--container--body--title">
                <input type="radio" required={requiredOption && titleSelected === valueTitle} id={"INTERNATIONAL"} name={valueTitle+"paymentMethod"} value={"INTERNATIONAL"} checked={"INTERNATIONAL" === valueOption}
                onChange={(e)=>{
                    handleChangeOption(e.target.value)
                }}/>
                <label htmlFor={"INTERNATIONAL"}><span>{<ICCreditCard/>}<div style={{display:"inline", gap:"2px"}}>Thẻ thanh toán quốc tế<span className="sub--title"> </span></div></span></label>
            </div>
            <div className="button--radio--container--body--title">
                <input type="radio" required={requiredOption && titleSelected === valueTitle} id={"installment"} name={valueTitle+"paymentMethod"} value={"installment"} checked={"installment" === valueOption}
                onChange={(e)=>{
                    handleChangeOption(e.target.value)
                }}/>
                <label htmlFor={"installment"}><span>{<ICCreditCardClock/>}<div style={{display:"inline", gap:"2px"}}>Trả góp 0% lãi suất <span className="sub--title" style={{fontSize:"10px"}}>Có thể phát sinh thêm phí tùy theo chính sách của ngân hàng</span></div></span></label>
            </div>
            <div className="button--radio--container--body--title">
                <input type="radio" required={requiredOption && titleSelected === valueTitle} id={"QR"} name={valueTitle+"paymentMethod"} value={"QR"} checked={"QR" === valueOption}
                onChange={(e)=>{
                    handleChangeOption(e.target.value)
                }}/>
                <label htmlFor={"QR"}><span>{<ICQr/>}<div style={{display:"inline", gap:"2px"}}>Quét mã QR code thanh toán<span className="sub--title" style={{fontSize:"10px"}}>Hỗ trợ ứng dụng ngân hàng và ví điện tử: Zalo Pay, Shopee Pay, VN Pay,...</span></div></span></label>
            </div>
            {/* <div className="button--radio--container--body--title">
                <input type="radio" required={requiredOption && titleSelected === valueTitle} id={"MOMO"} name={valueTitle+"paymentMethod"} value={"MOMO"} checked={"MOMO" === valueOption}
                onChange={(e)=>{
                    handleChangeOption(e.target.value)
                }}/>
                <label htmlFor={"MOMO"}><span>{<ICMomo/>}Thanh toán qua ví điện tử Momo</span></label>
            </div> */}
            {/* <div className="button--radio--container--body--title">
                <input type="radio" required={requiredOption && titleSelected === valueTitle} id={"ZALOPAY"} name={valueTitle+"paymentMethod"} value={"ZALOPAY"} checked={"ZALOPAY" === valueOption}
                onChange={(e)=>{
                    handleChangeOption(e.target.value)
                }}/>
                <label htmlFor={"ZALOPAY"}><span>{<ICZalo/>}Thanh toán qua ví điện tử Zalo Pay</span></label>
            </div> */}
            
        </div>}
        
        </div> );
}
 
export default ButtonRadio;