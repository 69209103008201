import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dataBlogPage: {},
    section: undefined,
    blogs: [],
    totalBlogs: 0,
    blog: {},
};

export const BlogPageReducer = createSlice({
    name: "dataBlogPage",
    initialState,
    reducers: {
        setDataBlogPage: (state, action) => {
            const section = action.payload.section;
            if(state.section !== section) {
                state.section = section;
                if (
                    !state.dataBlogPage[section] ||
                    state.dataBlogPage[section].length === 0
                ) {
                    state.dataBlogPage[section] = action.payload;
                }
            }
            
        },
        setBlogs: (state, action) => {
            state.blogs = action.payload.data;
            state.totalBlogs = action.payload.count;
        },
        setBlog: (state, action) =>{
            state.blog = action.payload;
        }
    },
});

export const { setDataBlogPage, setBlogs, setBlog } = BlogPageReducer.actions;

export default BlogPageReducer.reducer;
