
import ICClose from "../../assets/icons/ICClose";
import ICError from "../../assets/icons/ICError";
import "./Input.scss";

const TextFiled = ({ id, posIcon, placeholder, value, setValue, required, onKeyDown }) => {

  return (
    <div className="input--container">
      {posIcon && <span className="pos--icon">{posIcon}</span>}

      <input
        id={id}
        type="text"
        placeholder={placeholder??"Placeholder"}
        value={value}
        required={required}
        onChange={(e) => {if(setValue)setValue(e.target.value)}}
        onKeyDown={onKeyDown}
      />
      <span className="icon" onClick={() => setValue("")}>
        <ICClose width={16} height={16} />
      </span>
      <span className="icon--error" onClick={() => setValue("")}>
        <ICError width={16} height={16} />
      </span>
    </div>
  );
};
export default TextFiled;
