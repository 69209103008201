import { useNavigate, useSearchParams } from "react-router-dom";
import ICClose from "../../assets/icons/ICClose";
import ButtonIconLarge from "../Button/ButtonIconLarge";
import ButtonMedium from "../Button/ButtonMedium";
import SearchTextField from "../Input/SearchTextField";
import ProductCard from "../ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDataProduct } from "../../store/actions/productCategory.action";
import Loading from "../Loading/Loading";

const SearchComponent = () => {
  function useDebounce(value, delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const dispatch = useDispatch();
  const { dataProductCategory, loading } = useSelector(
    (state) => state.dataProductCategory
  );

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const nameSearch = searchParams.get("name");

  const setSearchName = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value !== "")
        prevParams.set("name", value.toString());
      else {
        prevParams.delete("name");
      }
      return prevParams;
    });
  };

  const searchDebounce = useDebounce(nameSearch?.trim());

  const closeSearch = () => {
    const search = document.getElementById("search--header");
    if (search) {
      search.classList.add("shrink");
    }
  };

  useEffect(() => {
    if (searchDebounce)
      dispatch(
        getDataProduct({
          limit: 4,
          offset: 0,
          name: searchDebounce,
        })
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounce]);

  return (
    <div className="search shrink" id="search--header">
      <div className="search--title">
        <span>Tìm kiếm</span>
        <ButtonIconLarge
          icon={<ICClose width={24} height={24} />}
          onClick={() => {
            closeSearch();
            setSearchName("");
            dispatch(
              getDataProduct({
                limit: 15,
                sort: "price__inc",
                offset: 0
              })
            );
          }}
        />
      </div>
      <div className="search--body">
        <div className="search--body--category">
          <SearchTextField
            id={"inputSearchHeader"}
            value={nameSearch ?? ""}
            setValue={(value) => setSearchName(value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                closeSearch();
                navigate(`/tim-kiem?name=${nameSearch}`);
              }
            }}
          />
        </div>
        {loading === true ? (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Loading />
          </div>
        ) : (
          searchDebounce && (
            <>
              <div className="search--body--header">
                <span>Top sản phẩm</span>
                <ButtonMedium title="Xem tất cả" onClick={()=>{
                  closeSearch();
                  navigate(`/tim-kiem?name=${nameSearch}`);
                }}/>
              </div>
              <div className="search--body--result">
                {dataProductCategory?.products
                  ?.filter((_, i) => i < 4)
                  .map((product) => (
                    <div
                      className="search--body--result-item"
                      key={product._id}
                      onClick={(e)=>{
                        e.stopPropagation();
                        closeSearch()
                      }}
                    >
                      <ProductCard product={product} />
                    </div>
                  ))}
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
