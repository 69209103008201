import React from "react";
import { useDispatch } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  removeInCart,
} from "../../store/reducers/cartLocal.reducers";
import { formatToVND } from "../../utils/currency";
import "./CardCheckout.scss";

export default function CardCheckout({ data }) {
  const dispatch = useDispatch();

  return (
    <main className="cardCheckout">
      <div className="cardCheckout__image">
        <img
          src={data?.img}
          alt={data?.product_name}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <div className="cardCheckout__info">
        <h4 className="cardCheckout__info__name">{data?.product_name}</h4>

        <div>
          {data?.features?.map((fItem) => {
            return <p className="cardCheckout__info__option">{fItem}</p>;
          })}
        </div>

        <div className="cardCheckout__info__price">
          {data?.price_after !== data?.price_original && (
            <span className="cardCheckout__info__price__org">
              {formatToVND(data?.price_original)}
            </span>
          )}

          <span
            className={`cardCheckout__info__price__sale ${
              data?.price_after === data?.price_original && "black"
            }`}
          >
            {formatToVND(data?.price_after)}
          </span>
        </div>

        <div className="cardCheckout__info__qty">
          {data?.quantity > 1 && (
            <button
              className="btn-sub"
              onClick={() => {
                dispatch(decreaseQuantity(data.price_id));
              }}
            >
              -
            </button>
          )}

          <span className="cardCheckout__info__qty__number">
            {data?.quantity}
          </span>

          <button
            className="btn-add"
            onClick={() => {
              dispatch(increaseQuantity(data.price_id));
            }}
          >
            +
          </button>
        </div>
      </div>

      <button
        title="Xóa khỏi giỏ hàng"
        className="cardCheckout__remove"
        onClick={() => {
          dispatch(removeInCart(data.price_id));
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M5.3332 15.8782L4.45508 15L9.12175 10.3334L4.45508 5.6667L5.3332 4.78857L9.99987 9.45524L14.6665 4.78857L15.5447 5.6667L10.878 10.3334L15.5447 15L14.6665 15.8782L9.99987 11.2115L5.3332 15.8782Z"
            fill="#121212"
          />
        </svg>
      </button>
    </main>
  );
}
