import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  order: localStorage.getItem("order")?JSON.parse(localStorage.getItem("order")??{}):localStorage.getItem("order"),
};

const updateLocalStorage = (detail) => {
  localStorage.setItem("order", JSON.stringify(detail));
};

export const OrderReducer = createSlice({
  name: "order",
  initialState,
  reducers: {
      clearOrder: (state) => {
         localStorage.removeItem("order")
         state.order = undefined
       },
       updateOrder:(state, action)=>{
         updateLocalStorage(action.payload);
         state.order = action.payload;
       }
  },
});

// eslint-disable-next-line no-empty-pattern
export const {clearOrder, updateOrder} = OrderReducer.actions;

export default OrderReducer.reducer;
