import AccountInfoPage from "../pages/AccountInfo/AccountInfoPage";
import AddressPage from "../pages/Address/AddressPage";
import BlogPage from "../pages/Blog/BlogPage";
import BlogDetailPage from "../pages/BlogDetail/BlogDetailPage";
import CartPage from "../pages/Cart/CartPage";
import CheckoutPage from "../pages/Checkout/CheckoutPage";
import AboutUsPage from "../pages/Contact/AboutUsPage";
import FAQPage from "../pages/FAQ/FAQPage";
import HomePage from "../pages/Home/HomePage";
import LoginPage from "../pages/Login/LoginPage";
import RegisterPage from "../pages/Login/RegisterPage";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import PaymentPage from "../pages/Payment/PaymentPage";
import PolicyPage from "../pages/Policy/PolicyPage";
import ProductCategory from "../pages/ProductCategory/ProductCategory";
import ProductDetail from "../pages/ProductDetail/ProductDetail";
import SearchPage from "../pages/SearchPage/SearchPage";

const routers = [
  {
    path: "/",
    element: <HomePage />,
    private: false,
    isChild: true,
  },
  {
    // path: "/product-detail",
    path: "/san-pham/:name/:_id",
    element: <ProductDetail />,
    private: false,
    isChild: true,
  },
  {
    path: "/sofa/:type",
    element: <ProductCategory />,
    private: false,
    isChild: true,
  },
  {
    path: "/tim-kiem",
    element: <SearchPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/gio-hang",
    element: <CartPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/thanh-toan",
    element: <CheckoutPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/thanh-toan/trang-thai",
    element: <PaymentPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/dieu-khoan-chinh-sach",
    element: <PolicyPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/ve-chung-toi",
    element: <AboutUsPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/dia-chi",
    element: <AddressPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/dang-nhap",
    element: <LoginPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/dang-ky",
    element: <RegisterPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/thong-tin-ca-nhan",
    element: <AccountInfoPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/tin-tuc",
    element: <BlogPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/tin-tuc/:id",
    element: <BlogDetailPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/faq",
    element: <FAQPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/*",
    element: <PageNotFound />,
    private: false,
  },
];

export default routers;
