import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ButtonRadio from "../../components/Button/ButtonRadio";
import ButtonSmall from "../../components/Button/ButtonSmall";
import ButtonSolidMedium from "../../components/Button/ButtonSolidMedium";
import InputCheckbox from "../../components/Input/InputCheckbox";
import InputDropdown from "../../components/Input/InputDropdown";
import TextField from "../../components/Input/TextFiled";
import { payMomo } from "../../store/actions/momo.action";
import { createOderAction } from "../../store/actions/order.action";
import { getProvinces } from "../../store/actions/provinces.action";
import { fetchRecentlyProductsAction } from "../../store/actions/recentlyProduct.action";
import { updateUserDetail } from "../../store/reducers/userDetailLocal.reducers";
import callApi from "../../utils/callApi";
import { formatToVND } from "../../utils/currency";
import { TypePayment } from "../../utils/enum";
import { calculateTotalPrice } from "../../utils/function";
import {
  makeInvoice,
  makeInvoiceInstallmentAtOnePaySite,
} from "../../utils/payone/Main";
import "./CheckoutPage.scss";

const CheckoutPage = () => {
  const { cartLocal } = useSelector((state) => state.cartLocal);
  const { userDetailLocal } = useSelector((state) => state.userDetailLocal);

  const [cartData, setCartData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [bankOption, setBankOption] = useState("");

  const { dataProvinces } = useSelector((state) => state.dataProvinces);
  const { recentlyProduct, localIds } = useSelector(
    (state) => state.recentlyProduct
  );
  const [surName, setSurName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const [address, setAddress] = useState("");
  const [usePersonalAddress, setUsePersonalAddress] = useState(false);
  const [cityShipping, setCityShipping] = useState("");
  const [districtShipping, setDistrictShipping] = useState("");
  const [wardShipping, setWardShipping] = useState("");
  const [addressShipping, setAddressShipping] = useState("");
  const [paymethod, setPaymethod] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {order} = useSelector((state)=>state.order)

  const handlePay = async (res) => {
    const order = res.data.data;
    let amount = order.amount;
    if (order.type === TypePayment.DEPOSIT) amount = Number(totalPrice) / 2;
    if (bankOption === "MOMO") {
      const baseURL = `${window.location.protocol}//${
        window.location.hostname
      }${window.location.port ? `:${window.location.port}` : ""}`;
      let returnURL = baseURL + "/payment";
      dispatch(
        payMomo({
          amount: amount.toString(),
          orderId: order._id,
          redirectUrl: returnURL,
        })
      );
    } else if (bankOption === "installment") {
      makeInvoiceInstallmentAtOnePaySite({
        amount: amount,
        orderInfo: order._id,
        vpcMerTxnRef: order.code,
        cardList: bankOption,
        customerId: phone,
        customerEmail: email,
        customerPhone: phone,
      });
    } else {
      makeInvoice({
        amount: amount,
        orderInfo: order._id,
        vpcMerTxnRef: order.code,
        cardList: bankOption,
        customerId: phone,
        customerEmail: email,
        customerPhone: phone,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const time = Date.now();
    const vpcMerTxnRef = "ORDER_" + time + generateRandomString(10);
    if (paymethod === "FULL") {
      dispatch(
        createOderAction({
          code: vpcMerTxnRef,
          vpcMerTxnRef,
          amount: totalPrice * 0.95,
          shippingFee: 0,
          discount: 0,
          merchTxnRef: vpcMerTxnRef,
          address: `${city}, ${district}, ${ward}, ${address}`,
          phone,
          items: cartLocal?.listProduct?.map((cart) => {
            return { price: cart?.product?._id, quantity: cart.quantity };
          }),
          type: 1,
          name_owner: `${surName} ${firstName}`,
        })
      ).then((res) => handlePay(res));
      // await createOderAction().then(async (res) => {
      // if (res) {
      //   if (bankOption === "MOMO") {
      //     const baseURL = `${window.location.protocol}//${
      //       window.location.hostname
      //     }${window.location.port ? `:${window.location.port}` : ""}`;
      //     let returnURL = baseURL + "/payment";
      //     dispatch(
      //       payMomo({
      //         amount: (totalPrice * 0.95).toString(),
      //         orderId: res.data.data._id +"/" + vpcMerTxnRef,
      //         redirectUrl: returnURL,
      //       })
      //     );
      //   } else if (bankOption === "installment") {
      //     makeInvoiceInstallmentAtOnePaySite({
      //       amount: totalPrice / 2,
      //       orderInfo: res.data.data._id +"/" + vpcMerTxnRef,
      //       vpcMerTxnRef,
      //       cardList: bankOption,
      //       customerId: phone,
      //       customerEmail: email,
      //       customerPhone: phone,
      //       deposit: true,
      //     });
      //   } else {
      //     makeInvoice({
      //       amount: totalPrice * 0.95,
      //       orderInfo: res.data.data._id +"/" + vpcMerTxnRef,
      //       vpcMerTxnRef,
      //       cardList: bankOption,
      //       customerId: phone,
      //       customerEmail: email,
      //       customerPhone: phone,
      //     });
      //   }
      // }
      // });
    } else if (paymethod === "DEPOSIT") {
      dispatch(
        createOderAction({
          code: vpcMerTxnRef,
          vpcMerTxnRef,
          amount: totalPrice / 2,
          shippingFee: 0,
          discount: 0,
          merchTxnRef: vpcMerTxnRef,
          address: `${city}, ${district}, ${ward}, ${address}`,
          phone,
          items: cartLocal?.listProduct?.map((cart) => {
            return { price: cart?.product?._id, quantity: cart.quantity };
          }),
          type: 2,
          name_owner: `${surName} ${firstName}`,
        })
      ).then((res) => handlePay(res));
      // await .then(async (res) => {
      //   if (res) {
      //     if (bankOption === "MOMO") {
      //       const baseURL = `${window.location.protocol}//${
      //         window.location.hostname
      //       }${window.location.port ? `:${window.location.port}` : ""}`;
      //       let returnURL = baseURL + "/payment";
      //       dispatch(
      //         payMomo({
      //           amount: (totalPrice / 2).toString(),
      //           orderId: res.data.data._id +"/" + vpcMerTxnRef,
      //           redirectUrl: returnURL,
      //         })
      //       );
      //     } else if (bankOption === "installment") {
      //       makeInvoiceInstallmentAtOnePaySite({
      //         amount: totalPrice / 2,
      //         orderInfo: res.data.data._id +"/" + vpcMerTxnRef,
      //         vpcMerTxnRef,
      //         cardList: null,
      //         customerId: phone,
      //         customerEmail: email,
      //         customerPhone: phone,
      //       });
      //     } else {
      //       makeInvoice({
      //         amount: totalPrice / 2,
      //         orderInfo: res.data.data._id +"/" + vpcMerTxnRef,
      //         vpcMerTxnRef,
      //         cardList: bankOption,
      //         customerId: phone,
      //         customerEmail: email,
      //         customerPhone: phone,
      //       });
      //     }
      //   }
      // });
    }
  };

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const convertData = (data) => {
    return data?.listProduct?.map((item) => ({
      price_id: item.product._id,
      quantity: item.quantity,
    }));
  };

  // useEffect(()=>{
  //   if(order){
  // let amount = order.amount;
  // if(order.type === TypePayment.DEPOSIT)amount = Number(amount)/(0.95*2);
  // if (bankOption === "MOMO") {
  //   const baseURL = `${window.location.protocol}//${
  //     window.location.hostname
  //   }${window.location.port ? `:${window.location.port}` : ""}`;
  //   let returnURL = baseURL + "/payment";
  //   dispatch(
  //     payMomo({
  //       amount: amount.toString(),
  //       orderId: order._id,
  //       redirectUrl: returnURL,
  //     })
  //   );
  // } else if (bankOption === "installment") {
  //   makeInvoiceInstallmentAtOnePaySite({
  //     amount: amount,
  //     orderInfo: order._id,
  //     vpcMerTxnRef: order.code,
  //     cardList: bankOption,
  //     customerId: phone,
  //     customerEmail: email,
  //     customerPhone: phone,
  //   });
  // } else {
  //   makeInvoice({
  //     amount: amount,
  //     orderInfo: order._id,
  //     vpcMerTxnRef: order.code,
  //     cardList: bankOption,
  //     customerId: phone,
  //     customerEmail: email,
  //     customerPhone: phone,
  //   });
  // }
  //   }
  // },[order])

  useEffect(() => {
    document.title = "Thanh toán - Là Nhà Decor";

    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    if (userDetailLocal?.detail) {
      setEmail(userDetailLocal.detail.email);
      setSurName(userDetailLocal.detail.surName);
      setFirstName(userDetailLocal.detail.firstName);
      setPhone(userDetailLocal.detail.phone);
      setAddress(userDetailLocal.detail.address);
      setWard(userDetailLocal.detail.ward);
      setDistrict(userDetailLocal.detail.district);
      setCity(userDetailLocal.detail.city);
    }
  }, [userDetailLocal]);

  useEffect(() => {
    const fetchData = async () => {
      const path = "carts";

      const dtBody = convertData(cartLocal);

      try {
        const result = await callApi(path, "POST", dtBody);

        if (result?.data?.data) {
          setCartData(result.data.data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    if (cartLocal?.listProduct?.length !== 0) {
      fetchData();
    }
  }, [cartLocal]);

  useEffect(() => {
    if (cartData) {
      setTotalPrice(calculateTotalPrice(cartData));
    }
  }, [cartData]);

  useEffect(() => {
    dispatch(
      updateUserDetail({
        surName,
        firstName,
        email,
        phone,
        city,
        district,
        ward,
        address,
        usePersonalAddress,
        cityShipping,
        districtShipping,
        wardShipping,
        addressShipping,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    surName,
    firstName,
    email,
    phone,
    city,
    district,
    ward,
    address,
    usePersonalAddress,
    cityShipping,
    districtShipping,
    wardShipping,
    addressShipping,
  ]);

  useEffect(() => {
    if (usePersonalAddress) {
      setCityShipping(city);
      setDistrictShipping(district);
      setWardShipping(ward);
      setAddressShipping(address);
    } else {
      setCityShipping(undefined);
      setDistrictShipping(undefined);
      setWardShipping(undefined);
      setAddressShipping(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usePersonalAddress, city, district, ward, address]);

  useEffect(() => {
    if (localIds) {
      dispatch(fetchRecentlyProductsAction(localIds));
    }
  }, [localIds]);
  if (cartLocal?.listProduct?.length === 0) {
    navigate("/");
    return <div></div>;
  }

  return (
    <div className="checkout--page">
      <section className="checkout--page--container">
        <div className="checkout--page--container--title">Thanh toán</div>

        <div className="checkout--page--container--main">
          <form
            className="checkout--page--container--main--form"
            onSubmit={handleSubmit}
            id="formInput"
          >
            <div className="form--info">
              <div className="form--info--container">
                <div className="form--info--container--address">
                  <p>Thông tin mua hàng</p>
                  <div className="form--info--container--address--sub">
                    <p>Bạn đang có tài khoản ?</p>
                    <ButtonSmall title={"Đăng nhập"} type={"button"} />
                  </div>
                  <div className="form--info--container--address--row">
                    <TextField
                      placeholder={"Họ"}
                      value={surName}
                      setValue={setSurName}
                      id="surName"
                      required={true}
                    />
                    <TextField
                      placeholder={"Tên"}
                      value={firstName}
                      setValue={setFirstName}
                      id="firstName"
                      required={true}
                    />
                  </div>
                  <div className="form--info--container--address--row">
                    <TextField
                      placeholder={"Email"}
                      value={email}
                      setValue={setEmail}
                      id="email"
                      required={true}
                    />
                    <TextField
                      placeholder={"Điện thoại liên lạc"}
                      value={phone}
                      setValue={setPhone}
                      id="phone"
                      required={true}
                    />
                  </div>
                  <InputDropdown
                    placeholder={"Thành phố/tỉnh"}
                    readOnly={true}
                    id={"city"}
                    name={"city"}
                    options={dataProvinces ?? []}
                    value={city}
                    setValue={setCity}
                    required={true}
                  />
                  <div className="form--info--container--address--row">
                    <InputDropdown
                      placeholder={"Quận/huyện"}
                      readOnly={true}
                      id={"district"}
                      name={"district"}
                      options={
                        dataProvinces?.find((ci) => ci.codename === city)
                          ?.districts ?? []
                      }
                      value={district}
                      setValue={setDistrict}
                      required={true}
                    />
                    <InputDropdown
                      placeholder={"Phường/xã"}
                      readOnly={true}
                      id={"ward"}
                      name={"ward"}
                      options={
                        dataProvinces
                          ?.find((ci) => ci.codename === city)
                          ?.districts?.find((dis) => dis.codename === district)
                          ?.wards ?? []
                      }
                      value={ward}
                      setValue={setWard}
                      required={true}
                    />
                  </div>
                  <TextField
                    placeholder={"Địa chỉ"}
                    value={address}
                    setValue={setAddress}
                    id="address"
                    required={true}
                  />
                  <InputCheckbox
                    title={"Sử dụng làm địa chỉ giao hàng"}
                    value={usePersonalAddress}
                    setValue={setUsePersonalAddress}
                  />
                </div>
                {!usePersonalAddress && (
                  <div className="form--info--container--address">
                    <p>Địa chỉ giao hàng</p>
                    <InputDropdown
                      placeholder={"Thành phố/tỉnh"}
                      readOnly={true}
                      id={"cityShipping"}
                      name={"cityShipping"}
                      options={dataProvinces ?? []}
                      value={cityShipping}
                      setValue={setCityShipping}
                      required={!usePersonalAddress}
                    />
                    <div className="form--info--container--address--row">
                      <InputDropdown
                        placeholder={"Quận/huyện"}
                        readOnly={true}
                        id={"districtShipping"}
                        name={"districtShipping"}
                        options={
                          dataProvinces?.find(
                            (ci) => ci.codename === cityShipping
                          )?.districts ?? []
                        }
                        value={districtShipping}
                        setValue={setDistrictShipping}
                        required={!usePersonalAddress}
                      />
                      <InputDropdown
                        placeholder={"Phường/xã"}
                        readOnly={true}
                        id={"wardShipping"}
                        name={"wardShipping"}
                        options={
                          dataProvinces
                            ?.find((ci) => ci.codename === cityShipping)
                            ?.districts?.find(
                              (dis) => dis.codename === districtShipping
                            )?.wards ?? []
                        }
                        value={wardShipping}
                        setValue={setWardShipping}
                        required={!usePersonalAddress}
                      />
                    </div>
                    <TextField
                      placeholder={"Địa chỉ"}
                      id="addressShipping"
                      required={!usePersonalAddress}
                    />
                  </div>
                )}

                <div className="form--info--container--address">
                  <p>Thanh toán</p>
                  <ButtonRadio
                    id={"DEPOSIT"}
                    name={"payment"}
                    title={"Đặt cọc 50% sản phẩm"}
                    subtitle={
                      "Phần còn lại sẽ được thanh toán hoàn toàn sau khi Là Nhà Decor gửi hình ảnh & video sản phẩm đã được hoàn thiện"
                    }
                    valueTitle={"DEPOSIT"}
                    titleSelected={paymethod}
                    handleChangeTitleSelected={(value) => {
                      setPaymethod(value);
                    }}
                    requiredTitle={true}
                    valueOption={bankOption}
                    requiredOption={true}
                    handleChangeOption={(value) => {
                      setBankOption(value);
                    }}
                  />
                  <ButtonRadio
                    id={"FULL"}
                    name={"payment"}
                    title={"Thanh toán toàn bộ đơn hàng"}
                    subtitle={
                      "Giảm 5% tổng đơn hàng khi thanh toán toàn bộ đơn hàng"
                    }
                    valueTitle={"FULL"}
                    titleSelected={paymethod}
                    handleChangeTitleSelected={(value) => {
                      setPaymethod(value);
                    }}
                    requiredTitle={true}
                    valueOption={bankOption}
                    requiredOption={true}
                    handleChangeOption={(value) => {
                      setBankOption(value);
                    }}
                  />
                  {/* <ButtonRadio id={"QR"} posIcon={<ICBank/>} name={"paymethod"} title={"Thanh toán qua Smart Banking"} value={"QR"} selected={""} handleChange={()=>{}}/> */}
                </div>
              </div>
              <div className="form--info--policy">
                Nhấn "Đặt hàng" đồng nghĩa với việc bạn đồng ý tuân theo
                <ButtonSmall
                  title={"Các điều khoản & điều kiện của Là Nhà Decor"}
                  type={"button"}
                  onClick={() => {}}
                />
              </div>
              <div className="form--info--button">
                <ButtonSolidMedium
                  title={"Đặt hàng"}
                  onClick={() => {
                    const form = document.getElementById("formInput");
                    if (form) form.classList.add("show--error");
                  }}
                  // style={{ width: "390px" }}
                />
              </div>
            </div>
            <div className="order--sumary"></div>
          </form>

          <div className="checkout--page--container--main--product">
            <h5 className="checkout--page--container--main--product--title">
              Thông tin đơn hàng
            </h5>

            <div className="col-12">
              <table className="checkout-table col-12">
                <thead>
                  <tr className="checkout-table__header">
                    <th className="checkout-table__header__product">
                      SẢN PHẨM
                    </th>
                    <th className="checkout-table__header__qty">SỐ LƯỢNG</th>
                    <th className="checkout-table__header__price">GIÁ</th>
                  </tr>
                </thead>

                {cartLocal?.listProduct?.length > 0 && cartData?.length > 0 ? (
                  <tbody>
                    {cartData?.map((cItem) => (
                      <tr key={cItem._id} className="checkout-table__row">
                        <td className="checkout-table__row__product">
                          <img
                            src={cItem?.img}
                            alt={cItem?.product_name}
                            onContextMenu={(e) => e.preventDefault()}
                          />

                          <div className="checkout-table__row__product__name">
                            <span className="checkout-table__row__product__name__text">
                              {cItem?.product_name}
                            </span>
                            <span className="checkout-table__row__product__name__number">
                              {cItem?.quantity < 10
                                ? `0${cItem?.quantity}`
                                : cItem?.quantity}
                            </span>
                          </div>
                        </td>

                        <td className="checkout-table__row__qty">
                          {cItem?.quantity < 10
                            ? `0${cItem?.quantity}`
                            : cItem?.quantity}
                        </td>

                        <td className="checkout-table__row__price">
                          {cItem?.price_after !== cItem?.price_original && (
                            <p className="checkout-table__row__price__org">
                              {formatToVND(cItem?.price_original)}
                            </p>
                          )}

                          <p
                            className={`checkout-table__row__price__sale ${
                              cItem?.price_after === cItem?.price_original &&
                              "black"
                            }`}
                          >
                            {formatToVND(cItem?.price_after)}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    {cartLocal?.listProduct?.map((pItem, idx) => {
                      return (
                        <tr key={idx} className="checkout-table__row">
                          <td className="checkout-table__row__price">
                            <Skeleton height={64} />
                          </td>

                          <td className="checkout-table__row__qty px-2">
                            <Skeleton height={16} />
                          </td>

                          <td className="checkout-table__row__price">
                            <Skeleton height={16} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>

            <div className="line"></div>

            <div>
              <div className="delivery-container">
                <span className="delivery-container__left">Tổng tiền</span>
                <span className="delivery-container__right">
                  {formatToVND(totalPrice)}
                </span>
              </div>

              <div className="delivery-container">
                <span className="delivery-container__left">Phí vận chuyển</span>
                <span className="delivery-container__right">{cityShipping==="thanh_pho_ho_chi_minh"?"0 đ":"Liên hệ báo giá sau"}</span>
              </div>

              <div className="delivery-container">
                <span className="delivery-container__left">Giảm giá</span>
                <span className="delivery-container__right">0 ₫</span>
              </div>
            </div>

            <div className="line"></div>

            <div className="total-temp">
              <span className="total-temp__text">Tổng giá</span>

              <span className="total-temp__number">
                {formatToVND(totalPrice)}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CheckoutPage;
