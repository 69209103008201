import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import ButtonSolidMedium from "../../components/Button/ButtonSolidMedium";
import CardCheckout from "../../components/CardCheckout/CardCheckout";
import SwiperScroll from "../../components/SwiperScroll/SwiperScroll";
import callApi from "../../utils/callApi";
import { formatToVND } from "../../utils/currency";
import { calculateTotalPrice } from "../../utils/function";
import "./CartPage.scss";
import Loading from "../../components/Loading/Loading";
import Skeleton from "react-loading-skeleton";
import { fetchRecentlyProductsAction } from "../../store/actions/recentlyProduct.action";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../../components/ProductCard/ProductCard";
import { FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { stringToUrl } from "../../utils/url.utils";
import { TypeProduct } from "../../utils/enum";

const CartPage = () => {
  const { recentlyProduct, localIds } = useSelector(
    (state) => state.recentlyProduct
  );
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );
  const { cartLocal } = useSelector((state) => state.cartLocal);

  const [cartData, setCartData] = useState([]);
  const [code, setCode] = useState("");
  const [showInputCode, setShowInputCode] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const convertData = (data) => {
    return data?.listProduct?.map((item) => ({
      price_id: item.product._id,
      quantity: item.quantity,
    }));
  };

  useEffect(() => {
    document.title = "Giỏ hàng - Là Nhà Decor";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const path = "carts";

      const dtBody = convertData(cartLocal);

      try {
        const result = await callApi(path, "POST", dtBody);

        if (result?.data?.data) {
          setCartData(result.data.data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    if (cartLocal?.listProduct?.length !== 0) {
      fetchData();
    }
  }, [cartLocal]);

  useEffect(() => {
    if (cartData) {
      setTotalPrice(calculateTotalPrice(cartData));
    }
  }, [cartData]);

  useEffect(() => {
    if (localIds) {
      dispatch(fetchRecentlyProductsAction(localIds));
    }
  }, [localIds]);

  return (
    <div className="cart--page">
      {cartLocal?.listProduct?.length !== 0 ? (
        <section className="cart-product">
          {/* {cartData?.length > 0 ? null : <Loading />} */}

          {cartData?.length > 0 ? (
            <div className="cart-product__header">
              <div className="d-flex flex-column">
                <h2 className="cart-product__header__title">Giỏ hàng</h2>

                <p className="cart-product__header__qty">
                  {cartLocal?.listProduct?.length} sản phẩm trong giỏ hàng
                </p>
              </div>

              <button
                className="cart-product__header__btn-back hover-underline-animation"
                onClick={() =>{
                  const allProduct = dataProductCategory?.categoryFilter?.find((category) =>
                    category?.title?.toLowerCase().includes("tất cả"))
                  navigate(`/sofa/${stringToUrl(allProduct?.title)}`)
                }}
              >
                Tiếp tục mua sắm <ICChevronRight width={18} height={18} />
              </button>
            </div>
          ) : (
            <Skeleton height={40} />
          )}

          <div className="cart-product__body">
            {cartData?.length > 0 ? (
              <div className="cart-product__body__list-card">
                {cartData?.map((cItem) => {
                  return <CardCheckout key={cItem?.price_id} data={cItem} />;
                })}
              </div>
            ) : (
              <div className="cart-product__body__list-card">
                <Skeleton height={300} />
              </div>
            )}

            {cartData?.length > 0 ? (
              <div className="cart-product__body__summary">
                <h5 className="cart-product__body__summary__title">
                  Tóm tắt đơn hàng
                </h5>

                <div>
                  <div className="delivery-container">
                    <span className="delivery-container__left">Tổng tiền</span>
                    <span className="delivery-container__right">
                      {formatToVND(totalPrice)}
                    </span>
                  </div>

                  <div className="delivery-container">
                    <span className="delivery-container__left">
                      Phí vận chuyển
                    </span>
                    <span className="delivery-container__right">
                      Tính toán trong quá trình thanh toán
                    </span>
                  </div>

                  <div className="delivery-container">
                    <span className="delivery-container__left">Giảm giá</span>
                    <span className="delivery-container__right">0 ₫</span>
                  </div>
                </div>

                <div className="line"></div>

                <div className="total-temp">
                  <span className="total-temp__text">Tổng ước tính</span>

                  <span className="total-temp__number">
                    {formatToVND(totalPrice)}
                  </span>
                </div>

                <div className="mobile-submit">
                  <div className="mobile-submit__price">
                    <span className="mobile-submit__price__text">
                      Tổng ước tính
                    </span>

                    <span className="mobile-submit__price__number">
                      {formatToVND(totalPrice)}
                    </span>
                  </div>

                  <button
                    className="mobile-submit__button"
                    onClick={() => {
                      navigate("/thanh-toan");
                    }}
                  >
                    Đi đến thanh toán
                    <ICChevronRight width={18} height={18} />
                  </button>
                </div>

                <div className="line"></div>

                <div>
                  <div className="discount-code">
                    <span className="discount-code__title">Mã giảm giá</span>
                    <span
                      className="discount-code__button hover-underline-animation"
                      onClick={() => {
                        setShowInputCode(!showInputCode);
                      }}
                    >
                      Nhập mã
                    </span>
                  </div>

                  <div
                    className={`discount-input ${
                      showInputCode && "discount-input-show"
                    }`}
                  >
                    <div className="discount-input__group">
                      <input
                        type="text"
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />

                      <button
                        onClick={() => {
                          setCode("");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M4.26695 12.4359L3.56445 11.7334L7.29779 8.00004L3.56445 4.26671L4.26695 3.56421L8.00029 7.29754L11.7336 3.56421L12.4361 4.26671L8.70279 8.00004L12.4361 11.7334L11.7336 12.4359L8.00029 8.70254L4.26695 12.4359Z"
                            fill="#525257"
                          />
                        </svg>
                      </button>
                    </div>

                    <button className="discount-input__btn">Áp dụng</button>
                  </div>
                </div>

                <div className="line"></div>

                <div className="list-visaCard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="32"
                    viewBox="0 0 46 32"
                    fill="none"
                  >
                    <rect
                      y="0.000244141"
                      width="45.3333"
                      height="32"
                      rx="1"
                      fill="#F7F7F7"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.3341 21.1446H11.5877L9.52833 13.0568C9.43058 12.6848 9.22304 12.3558 8.91774 12.2008C8.15584 11.8113 7.31628 11.5012 6.40039 11.3449V11.0335H10.8245C11.4351 11.0335 11.893 11.5012 11.9694 12.0445L13.0379 17.8785L15.7829 11.0335H18.4529L14.3341 21.1446ZM19.9794 21.1446H17.3857L19.5214 11.0335H22.1151L19.9794 21.1446ZM25.4707 13.8346C25.547 13.29 26.0049 12.9786 26.5392 12.9786C27.3788 12.9004 28.2933 13.0568 29.0565 13.445L29.5145 11.268C28.7512 10.9567 27.9117 10.8003 27.1498 10.8003C24.6324 10.8003 22.8007 12.2008 22.8007 14.1446C22.8007 15.6233 24.0982 16.3998 25.0141 16.8675C26.0049 17.3339 26.3865 17.6453 26.3102 18.1117C26.3102 18.8113 25.547 19.1227 24.7851 19.1227C23.8692 19.1227 22.9533 18.8895 22.1151 18.4999L21.6571 20.6782C22.573 21.0664 23.5639 21.2228 24.4798 21.2228C27.3024 21.2996 29.0565 19.9005 29.0565 17.8003C29.0565 15.1556 25.4707 15.0006 25.4707 13.8346ZM38.1337 21.1446L36.0743 11.0335H33.8623C33.4043 11.0335 32.9464 11.3449 32.7937 11.8113L28.9802 21.1446H31.6502L32.1831 19.6672H35.4637L35.769 21.1446H38.1337ZM34.2439 13.7564L35.0058 17.5671H32.8701L34.2439 13.7564Z"
                      fill="#172B85"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="32"
                    viewBox="0 0 46 32"
                    fill="none"
                  >
                    <rect
                      x="0.333008"
                      y="0.000244141"
                      width="45.3333"
                      height="32"
                      rx="1"
                      fill="#F7F7F7"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.2383 22.4394C21.6596 23.7701 19.6117 24.5735 17.3739 24.5735C12.3808 24.5735 8.33301 20.5739 8.33301 15.6401C8.33301 10.7064 12.3808 6.70679 17.3739 6.70679C19.6117 6.70679 21.6596 7.51012 23.2383 8.84084C24.817 7.51012 26.8649 6.70679 29.1027 6.70679C34.0959 6.70679 38.1436 10.7064 38.1436 15.6401C38.1436 20.5739 34.0959 24.5735 29.1027 24.5735C26.8649 24.5735 24.817 23.7701 23.2383 22.4394Z"
                      fill="#ED0006"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.2383 22.4394C25.1822 20.8009 26.4148 18.3627 26.4148 15.6401C26.4148 12.9175 25.1822 10.4794 23.2383 8.84084C24.817 7.51012 26.8649 6.70679 29.1027 6.70679C34.0958 6.70679 38.1436 10.7064 38.1436 15.6401C38.1436 20.5739 34.0958 24.5735 29.1027 24.5735C26.8649 24.5735 24.817 23.7701 23.2383 22.4394Z"
                      fill="#F9A000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.238 22.4393C25.1819 20.8008 26.4145 18.3627 26.4145 15.6401C26.4145 12.9175 25.1819 10.4794 23.238 8.84082C21.2941 10.4794 20.0615 12.9175 20.0615 15.6401C20.0615 18.3627 21.2941 20.8008 23.238 22.4393Z"
                      fill="#FF5E00"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="32"
                    viewBox="0 0 46 32"
                    fill="none"
                  >
                    <rect
                      x="0.666992"
                      y="0.000244141"
                      width="45.3333"
                      height="32"
                      rx="1"
                      fill="#F7F7F7"
                    />
                    <path
                      d="M29.976 15.9393C30.6844 15.9547 31.3968 15.908 32.1023 15.9636C32.8168 16.0968 32.989 17.1785 32.3542 17.5327C31.9211 17.7661 31.4066 17.6197 30.9371 17.6608H29.976V15.9393ZM32.5119 13.9908C32.6692 14.5463 32.1337 15.045 31.5987 14.9685H29.976C29.9874 14.4447 29.9538 13.8761 29.9925 13.3798C30.6426 13.3981 31.2986 13.3425 31.9449 13.4089C32.2226 13.4787 32.455 13.707 32.5119 13.9908ZM36.4171 5.75297C36.4473 6.81382 36.4215 7.93074 36.4301 9.01312C36.4281 13.4136 36.4344 17.8141 36.4267 22.2148C36.3982 23.864 34.9366 25.2971 33.2988 25.33C31.6594 25.3367 30.0198 25.331 28.3802 25.3328V18.6802C30.1666 18.6709 31.954 18.6989 33.7397 18.6662C34.568 18.6142 35.4754 18.0676 35.514 17.156C35.6115 16.2404 34.7483 15.6072 33.9288 15.5072C33.6138 15.499 33.623 15.4153 33.9288 15.3788C34.7104 15.2099 35.3242 14.4009 35.0943 13.5907C34.8983 12.7386 33.9564 12.4087 33.173 12.4104C31.5757 12.3995 29.978 12.4089 28.3806 12.4057C28.3909 11.1637 28.359 9.92043 28.3977 8.67936C28.5243 7.05993 30.0227 5.72442 31.6374 5.75339C33.2308 5.75315 34.824 5.75315 36.4171 5.75309V5.75297Z"
                      fill="url(#paint0_linear_1344_26356)"
                    />
                    <path
                      d="M10.3286 8.84715C10.3694 7.20055 11.8373 5.77927 13.4729 5.75521C15.1062 5.75018 16.7398 5.75448 18.3731 5.75303C18.3686 11.2622 18.382 16.7719 18.3664 22.2808C18.3035 23.9074 16.8516 25.3016 15.2338 25.3303C13.5974 25.3363 11.9608 25.3312 10.3243 25.3328V18.4559C11.9139 18.8314 13.5808 18.9912 15.2024 18.7422C16.1717 18.5862 17.2323 18.1102 17.5604 17.1045C17.8019 16.2443 17.666 15.339 17.7019 14.4561V12.4058H14.8955C14.8829 13.7618 14.9214 15.1202 14.8752 16.4747C14.7996 17.3072 13.9751 17.8361 13.19 17.8079C12.2162 17.8182 10.2866 17.1022 10.2866 17.1022C10.2818 14.5615 10.3149 11.3797 10.3286 8.84739V8.84715Z"
                      fill="url(#paint1_linear_1344_26356)"
                    />
                    <path
                      d="M19.4195 13.2329C19.2718 13.2642 19.3898 12.7297 19.352 12.5269C19.3621 11.2449 19.331 9.96143 19.3692 8.68029C19.4954 7.05401 21.0053 5.71517 22.6266 5.75323H27.4012C27.3967 11.2624 27.41 16.7721 27.3945 22.281C27.3315 23.9076 25.8796 25.3017 24.2617 25.3306C22.6252 25.3367 20.9886 25.3315 19.352 25.3331V17.7984C20.4697 18.7155 21.9888 18.8583 23.3813 18.8608C24.431 18.8604 25.4747 18.6985 26.4941 18.4564V17.0761C25.3452 17.6487 23.9947 18.0124 22.7212 17.6834C21.8328 17.4623 21.1879 16.6037 21.2022 15.6869C21.0993 14.7334 21.6582 13.7268 22.5951 13.4434C23.7584 13.0792 25.0263 13.3577 26.1167 13.8312C26.3503 13.9535 26.5875 14.1052 26.494 13.7148V12.6298C24.6703 12.1959 22.7295 12.0362 20.8973 12.5082C20.367 12.6578 19.8503 12.8847 19.4195 13.233V13.2329Z"
                      fill="url(#paint2_linear_1344_26356)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1344_26356"
                        x1="28.3252"
                        y1="15.1243"
                        x2="36.3793"
                        y2="15.1243"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#58B03A" />
                        <stop offset="1" stop-color="#55B330" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_1344_26356"
                        x1="10.1531"
                        y1="15.7304"
                        x2="18.2412"
                        y2="15.7304"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#0F6EB6" />
                        <stop offset="1" stop-color="#006DBA" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_1344_26356"
                        x1="19.3402"
                        y1="15.3142"
                        x2="27.3997"
                        y2="15.3142"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#DE0D3D" />
                        <stop offset="1" stop-color="#E30138" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="32"
                    viewBox="0 0 46 32"
                    fill="none"
                  >
                    <rect
                      y="0.000244141"
                      width="45.3333"
                      height="32"
                      rx="1"
                      fill="#1F72CD"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.12722 11.3335L3.88574 20.9958H8.96339L9.59287 19.4552H11.0317L11.6612 20.9958H17.2503V19.82L17.7483 20.9958H20.6394L21.1374 19.7952V20.9958H32.7611L34.1745 19.4953L35.498 20.9958L41.4682 21.0082L37.2133 16.1916L41.4682 11.3335H35.5906L34.2147 12.8063L32.9329 11.3335H20.2878L19.202 13.8274L18.0907 11.3335H13.0237V12.4693L12.46 11.3335H8.12722ZM9.10972 12.7056H11.5848L14.3982 19.2576V12.7056H17.1095L19.2825 17.4034L21.2852 12.7056H23.983V19.6389H22.3415L22.3281 14.206L19.9348 19.6389H18.4664L16.0598 14.206V19.6389H12.6827L12.0425 18.0845H8.58359L7.94468 19.6376H6.1353L9.10972 12.7056ZM32.1598 12.7056H25.4849V19.6348H32.0564L34.1745 17.3384L36.2161 19.6348H38.3502L35.2483 16.1903L38.3502 12.7056H36.3087L34.2013 14.9757L32.1598 12.7056ZM10.3138 13.8786L9.17419 16.6476H11.452L10.3138 13.8786ZM27.1332 15.4068V14.1411V14.1399H31.2982L33.1155 16.164L31.2176 18.1993H27.1332V16.8175H30.7747V15.4068H27.1332Z"
                      fill="white"
                    />
                  </svg>
                  <div
                    style={{
                      display: "flex",
                      width: "46px",
                      padding: "4px 0px",
                      flexDirection: "column",
                      alignSelf: "stretch",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "1px",
                      background: "#F7F7F7",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_2351_33998)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.465 0.210205H1.53098C0.803346 0.210205 0.208008 0.805543 0.208008 1.53318V18.4672C0.208008 19.1949 0.803346 19.7902 1.53098 19.7902H18.465C19.1927 19.7902 19.788 19.1949 19.788 18.4672V1.53318C19.788 0.805543 19.1927 0.210205 18.465 0.210205Z"
                          fill="#A50064"
                        />
                        <path
                          d="M13.7547 9.40486C15.3092 9.40486 16.566 8.14804 16.566 6.59354C16.566 5.03905 15.3092 3.78223 13.7547 3.78223C12.2002 3.78223 10.9434 5.03905 10.9434 6.59354C10.9434 8.14804 12.2002 9.40486 13.7547 9.40486ZM13.7547 5.39625C14.4162 5.39625 14.952 5.93206 14.952 6.59354C14.952 7.25503 14.4162 7.79083 13.7547 7.79083C13.0932 7.79083 12.5574 7.25503 12.5574 6.59354C12.5574 5.93206 13.0932 5.39625 13.7547 5.39625Z"
                          fill="white"
                        />
                        <path
                          d="M13.7547 10.6221C12.2002 10.6221 10.9434 11.8789 10.9434 13.4334C10.9434 14.9879 12.2002 16.2447 13.7547 16.2447C15.3092 16.2447 16.566 14.9879 16.566 13.4334C16.566 11.8789 15.3092 10.6221 13.7547 10.6221ZM13.7547 14.6307C13.0932 14.6307 12.5574 14.0949 12.5574 13.4334C12.5574 12.7719 13.0932 12.2361 13.7547 12.2361C14.4162 12.2361 14.952 12.7719 14.952 13.4334C14.952 14.0949 14.4162 14.6307 13.7547 14.6307Z"
                          fill="white"
                        />
                        <path
                          d="M8.02667 10.6221C7.5504 10.6221 7.11382 10.7808 6.76323 11.0454C6.41264 10.7808 5.96945 10.6221 5.49979 10.6221C4.33557 10.6221 3.38965 11.568 3.38965 12.7322V16.2513H5.00368V12.7124C5.00368 12.4478 5.21535 12.2361 5.47995 12.2361C5.74454 12.2361 5.95622 12.4478 5.95622 12.7124V16.2447H7.57024V12.7124C7.57024 12.4478 7.78192 12.2361 8.04651 12.2361C8.31111 12.2361 8.52278 12.4478 8.52278 12.7124V16.2447H10.1302V12.7256C10.1302 11.568 9.19088 10.6221 8.02667 10.6221Z"
                          fill="white"
                        />
                        <path
                          d="M8.02645 3.78223C7.55018 3.78223 7.1136 3.94098 6.76301 4.20558C6.41242 3.94098 5.96922 3.78223 5.49957 3.78223C4.32874 3.78223 3.38281 4.72815 3.38281 5.89237V9.40486H4.99684V5.87252C4.99684 5.60793 5.20852 5.39625 5.47311 5.39625C5.7377 5.39625 5.94938 5.60793 5.94938 5.87252V9.40486H7.56341V5.87252C7.56341 5.60793 7.77508 5.39625 8.03968 5.39625C8.30427 5.39625 8.51595 5.60793 8.51595 5.87252V9.40486H10.13V5.89237C10.13 4.72815 9.19066 3.78223 8.02645 3.78223Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2351_33998">
                          <rect
                            width="19.58"
                            height="19.58"
                            fill="white"
                            transform="translate(0.208008 0.210205)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "46px",
                      padding: "4px 0px",
                      flexDirection: "column",
                      alignSelf: "stretch",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "1px",
                      background: "#F7F7F7",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.0594 1.00024H5.59682C3.23928 1.00024 1.32812 2.90291 1.32812 5.24996V14.7505C1.32812 17.0976 3.23928 19.0002 5.59682 19.0002H15.0594C17.417 19.0002 19.3281 17.0976 19.3281 14.7505V5.24996C19.3281 2.90291 17.417 1.00024 15.0594 1.00024Z"
                        fill="white"
                        stroke="#EAEAEA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M3.09893 5.84062C2.93446 4.82529 3.62764 3.86903 4.6475 3.70529L17.4063 1.6552C16.7476 1.24099 15.9673 1.00024 15.1307 1.00024H5.6681C3.31071 1.00024 1.39941 2.90305 1.39941 5.24996V14.7505C1.39941 16.9502 3.0779 18.7588 5.22952 18.9778L3.09968 5.84062H3.09893Z"
                        fill="#0068FF"
                      />
                      <path
                        d="M12.4734 6.6667C12.3923 6.58371 12.2887 6.54259 12.1632 6.54259C11.9785 6.54259 11.8433 6.63081 11.7585 6.80726C11.5775 6.59567 11.3296 6.4895 11.0157 6.4895C10.6387 6.4895 10.3218 6.63455 10.065 6.92389C9.80811 7.21324 9.67969 7.56614 9.67969 7.98259C9.67969 8.39903 9.80811 8.75193 10.065 9.04128C10.3218 9.33062 10.6387 9.47567 11.0157 9.47567C11.3296 9.47567 11.5775 9.3695 11.7585 9.15791C11.8433 9.33436 11.9785 9.42259 12.1632 9.42259C12.2887 9.42259 12.3923 9.38146 12.4734 9.29847C12.5545 9.21548 12.5951 9.1138 12.5951 8.99417V6.97174C12.5951 6.85212 12.5545 6.75044 12.4734 6.66745V6.6667ZM11.573 8.48427C11.4678 8.6196 11.3221 8.68689 11.1381 8.68689C10.9541 8.68689 10.8084 8.6196 10.7033 8.48427C10.5982 8.34894 10.5456 8.18221 10.5456 7.98259C10.5456 7.78296 10.5982 7.61623 10.7033 7.4809C10.8084 7.34558 10.9534 7.27829 11.1381 7.27829C11.3229 7.27829 11.4678 7.34558 11.573 7.4809C11.6781 7.61623 11.7307 7.78296 11.7307 7.98259C11.7307 8.18221 11.6781 8.34894 11.573 8.48427Z"
                        fill="#0068FF"
                      />
                      <path
                        d="M13.574 9.29845C13.4929 9.38144 13.3893 9.42257 13.2639 9.42257C13.1384 9.42257 13.0348 9.38144 12.9537 9.29845C12.8726 9.21546 12.832 9.11378 12.832 8.99415V5.37397C12.832 5.25434 12.8726 5.15266 12.9537 5.06967C13.0348 4.98668 13.1384 4.94556 13.2639 4.94556C13.3893 4.94556 13.4929 4.98668 13.574 5.06967C13.6551 5.15266 13.6957 5.25434 13.6957 5.37397V8.99415C13.6957 9.11453 13.6551 9.21546 13.574 9.29845Z"
                        fill="#0068FF"
                      />
                      <path
                        d="M16.4375 6.92316C16.1604 6.63382 15.8074 6.48877 15.3786 6.48877C14.9498 6.48877 14.5998 6.63157 14.3287 6.91793C14.0568 7.20354 13.9209 7.55868 13.9209 7.98185C13.9209 8.40503 14.0568 8.76017 14.3287 9.04578C14.6006 9.33139 14.9505 9.47494 15.3786 9.47494C15.8067 9.47494 16.1604 9.33064 16.4375 9.04055C16.7034 8.75793 16.8363 8.40503 16.8363 7.98185C16.8363 7.55868 16.7034 7.20578 16.4375 6.92316ZM15.8142 8.48354C15.709 8.61886 15.5641 8.68615 15.3793 8.68615C15.1946 8.68615 15.0497 8.61886 14.9445 8.48354C14.8394 8.34821 14.7868 8.18148 14.7868 7.98185C14.7868 7.78223 14.8394 7.6155 14.9445 7.48017C15.0497 7.34484 15.1946 7.27755 15.3793 7.27755C15.5641 7.27755 15.709 7.34484 15.8142 7.48017C15.9193 7.6155 15.9719 7.78223 15.9719 7.98185C15.9719 8.18148 15.9193 8.34821 15.8142 8.48354Z"
                        fill="#0068FF"
                      />
                      <path
                        d="M9.20118 6.58901C9.50683 6.19648 9.65929 5.93256 9.65929 5.79648C9.65929 5.48172 9.46027 5.32471 9.06224 5.32471H6.96394C6.79872 5.32471 6.6718 5.36359 6.58093 5.44134C6.49081 5.5191 6.44499 5.62003 6.44499 5.74415C6.44499 5.86826 6.49006 5.96919 6.58093 6.04695C6.6718 6.12471 6.79872 6.16359 6.96394 6.16359H8.40512L6.5599 8.53667C6.43899 8.69218 6.37891 8.82975 6.37891 8.95013C6.37891 9.29555 6.60796 9.46751 7.06607 9.46751H9.23122C9.57743 9.46751 9.75015 9.32321 9.75015 9.03387C9.75015 8.74452 9.57743 8.60022 9.23122 8.60022H7.63909L9.20118 6.58975V6.58901Z"
                        fill="#0068FF"
                      />
                      <path
                        d="M8.09781 11.7022H7.55859V12.8169H8.09781C8.26002 12.8169 8.3907 12.7646 8.49133 12.6592C8.59197 12.5538 8.64153 12.4207 8.64153 12.2592C8.64153 12.0977 8.59122 11.9646 8.49133 11.8592C8.3907 11.7538 8.26002 11.7014 8.09781 11.7014V11.7022Z"
                        fill="#03CA77"
                      />
                      <path
                        d="M10.7863 12.7505C10.6151 12.7505 10.4814 12.8133 10.3838 12.9382C10.2861 13.063 10.2373 13.2178 10.2373 13.4025C10.2373 13.5871 10.2861 13.7411 10.3838 13.8668C10.4814 13.9916 10.6151 14.0544 10.7863 14.0544C10.9575 14.0544 11.0912 13.9916 11.1888 13.8668C11.2865 13.7419 11.3353 13.5871 11.3353 13.4025C11.3353 13.2178 11.2865 13.0638 11.1888 12.9382C11.0912 12.8133 10.9568 12.7505 10.7863 12.7505Z"
                        fill="#03CA77"
                      />
                      <path
                        d="M14.5934 10.1082H6.78C6.5194 10.1082 6.30762 10.3182 6.30762 10.5777V15.1354C6.30762 15.3949 6.51865 15.605 6.78 15.605H12.948C12.8826 15.5437 12.8503 15.4719 12.8503 15.3896C12.8503 15.3224 12.8654 15.2521 12.8962 15.1781L13.1868 14.5343L12.3825 12.5351C12.3592 12.4782 12.3472 12.4162 12.3472 12.3489C12.3472 12.2614 12.384 12.1874 12.4583 12.1276C12.5327 12.067 12.61 12.0371 12.6911 12.0371C12.8699 12.0371 12.9915 12.1224 13.0554 12.2943L13.5563 13.6588L14.0925 12.2943C14.1601 12.1231 14.281 12.0371 14.4567 12.0371C14.5378 12.0371 14.6152 12.067 14.6895 12.1276C14.7639 12.1882 14.8007 12.2622 14.8007 12.3489C14.8007 12.4162 14.7887 12.4782 14.7654 12.5351L13.5736 15.4151C13.5413 15.4958 13.5015 15.5586 13.4557 15.6057H14.5927C14.8533 15.6057 15.065 15.3956 15.065 15.1362V10.5784C15.065 10.319 14.854 10.1089 14.5927 10.1089L14.5934 10.1082ZM9.07506 13.1743C8.82873 13.3986 8.51856 13.5108 8.14532 13.5108H7.55879V14.3736C7.55879 14.5037 7.52199 14.6039 7.44764 14.6756C7.37329 14.7474 7.27866 14.7833 7.16226 14.7833C7.04585 14.7833 6.95122 14.7474 6.87687 14.6756C6.80252 14.6039 6.76573 14.5029 6.76573 14.3736V11.4181C6.76573 11.1444 6.90316 11.0076 7.17803 11.0076H8.11303C8.5043 11.0076 8.82722 11.1242 9.08256 11.3575C9.3379 11.5908 9.46558 11.8928 9.46558 12.2652C9.46558 12.6375 9.3349 12.9433 9.0743 13.1751L9.07506 13.1743ZM12.1354 14.3377C12.1354 14.4491 12.0978 14.5425 12.0227 14.6196C11.9476 14.6966 11.8515 14.7347 11.7351 14.7347C11.5639 14.7347 11.4392 14.6532 11.3611 14.4895C11.1936 14.6853 10.9646 14.7833 10.6739 14.7833C10.3255 14.7833 10.0318 14.6495 9.79452 14.3818C9.5572 14.1141 9.43855 13.7874 9.43855 13.4016C9.43855 13.0158 9.5572 12.6891 9.79452 12.4214C10.0318 12.1538 10.3255 12.0199 10.6739 12.0199C10.9646 12.0199 11.1936 12.1179 11.3611 12.3138C11.4392 12.1508 11.5646 12.0685 11.7351 12.0685C11.8515 12.0685 11.9469 12.1067 12.0227 12.1837C12.0978 12.2607 12.1354 12.3541 12.1354 12.4655V14.3377Z"
                        fill="#03CA77"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ) : (
              <div className="cart-product__body__summary">
                <Skeleton height={200} />
              </div>
            )}
          </div>
        </section>
      ) : (
        <section className="cart--page--category">
          <div className="cart--page--category--container">
            <div className="cart--page--category--container--content">
              <div className="cart--page--category--container--content--title">
                <span className="cart--page--category--container--content--title">
                  Giỏ hàng trống
                </span>
              </div>
              <div className="cart--page--category--container--content--description">
                <span className="cart--page--category--container--content--description">
                  Hãy lựa chọn sản phẩm
                </span>
              </div>
            </div>
            <ButtonSolidMedium
              title={"Xem tất cả sản phầm"}
              preIcon={<ICChevronRight width={24} height={24} />}
              onClick={() => {
                const allProduct = dataProductCategory?.categoryFilter?.find((category) =>
                  category?.title?.toLowerCase().includes("tất cả"))
                navigate(`/sofa/${stringToUrl(allProduct?.title)}`)
              }}
            />
          </div>
        </section>
      )}

      <SwiperScroll title={"Bạn đã xem"} data={recentlyProduct} />
    </div>
  );
};

export default CartPage;
