import "./Button.scss";

const ButtonSolidMedium = ({ title, type, preIcon, onClick, style, disabled }) => {
  return (
    <button className={`btn--solid medium ${preIcon??"pre--icon"}`} onClick={onClick} type={type} style={{...style}} disabled={disabled}>
      {title}
      {preIcon}
    </button>
  );
};

export default ButtonSolidMedium;
