const ICCreditCardClock = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width ?? undefined, height: height ?? undefined }}
      viewBox={`0 0 24 24`}
      fill="currentColor"
    >
      <path d="M4 18V10.1538V10.5557V6V18ZM4 8.404H20V6.30775C20 6.23075 19.9679 6.16025 19.9038 6.09625C19.8398 6.03208 19.7693 6 19.6923 6H4.30775C4.23075 6 4.16025 6.03208 4.09625 6.09625C4.03208 6.16025 4 6.23075 4 6.30775V8.404ZM11.4827 19.5H4.30775C3.80258 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6923V6.30775C2.5 5.80258 2.675 5.375 3.025 5.025C3.375 4.675 3.80258 4.5 4.30775 4.5H19.6923C20.1974 4.5 20.625 4.675 20.975 5.025C21.325 5.375 21.5 5.80258 21.5 6.30775V11.2595C20.9385 10.8942 20.3366 10.6186 19.6943 10.4328C19.0519 10.2467 18.3929 10.1538 17.7172 10.1538C16.9904 10.1538 16.2857 10.2791 15.603 10.5298C14.9203 10.7804 14.304 11.1358 13.754 11.596H4V17.6923C4 17.7692 4.03208 17.8398 4.09625 17.9038C4.16025 17.9679 4.23075 18 4.30775 18H11.0558C11.0994 18.2667 11.1552 18.5234 11.223 18.7703C11.291 19.0169 11.3776 19.2602 11.4827 19.5ZM19.5403 19.3173L20.1635 18.6943L18.2788 16.8095V14.0385H17.3943V17.1713L19.5403 19.3173ZM17.852 21.6443C16.5482 21.6443 15.4423 21.1946 14.5345 20.2952C13.6268 19.3959 13.173 18.2943 13.173 16.9905C13.173 15.6867 13.6268 14.5834 14.5345 13.6808C15.4423 12.7783 16.5482 12.327 17.852 12.327C19.1455 12.327 20.2419 12.7808 21.1413 13.6885C22.0406 14.5962 22.4902 15.6968 22.4902 16.9905C22.4902 18.284 22.0406 19.383 21.1413 20.2875C20.2419 21.192 19.1455 21.6443 17.852 21.6443Z"/>
    </svg>
  );
};
export default ICCreditCardClock;
