import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataHeroSesstion:{},
  section: undefined, // Lưu dữ liệu từng section
  loading: false,
  error: null,
};

const FAQReducer = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setFAQSession: (state, action) => {
      const section = action.payload.section;
      if (state.section !== section) {
        state.section = section;
        if (
          !state.dataHeroSesstion[section] ||
          state.dataHeroSesstion[section].length === 0
        ) {
          state.dataHeroSesstion[section] = action.payload;
        }
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload; // Cập nhật trạng thái loading
    },
    setError: (state, action) => {
      state.error = action.payload; // Lưu thông báo lỗi nếu có
    },
  },
});

export const { setFAQSession, setLoading, setError } = FAQReducer.actions;

export default FAQReducer.reducer;
