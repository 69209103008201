import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentlyProduct: [],
  localIds:JSON.parse(localStorage.getItem("localIds"))??[],
};

const updateLocalStorage = (ids) => {
  localStorage.setItem("localIds", JSON.stringify(ids));
};

export const RecentlyProduct = createSlice({
  name: "recentlyProduct",
  initialState,
  reducers: {
    clearRecentlyProduct: (state) => {
        state.recentlyProduct=[]
        updateLocalStorage([]);
    },
    updateRecentlyProduct:(state, action)=>{
        if(action.payload){
          state.recentlyProduct=action.payload;
        }else state.recentlyProduct=[]
    },
    updateRecentlyProductId:(state, action)=>{
        let products = state.localIds;

        const index = state.localIds.findIndex(pro=>pro===action.payload);
        products = products.filter((_,i)=>i<9);
        console.log(index, products)
        if(index===-1){
            products.push(action.payload);
        }else{
            products.splice(index, 1);
            products.push(action.payload);
        }
        state.localIds = products;
        updateLocalStorage(products);
    }
  },
});

export const { updateRecentlyProduct, clearRecentlyProduct ,updateRecentlyProductId} = RecentlyProduct.actions;

export default RecentlyProduct.reducer;
