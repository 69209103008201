import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sections: {}, // Store data by sections
  loading: false,
  error: null,
};

const AddressReducer = createSlice({
  name: "address",
  initialState,
  reducers: {
    setDataSection: (state, action) => {
      const section = action.payload.section; // Section name
      state.sections[section] = action.payload; // Update section data
    },
    setLoading: (state, action) => {
      state.loading = action.payload; // Update loading state
    },
    setError: (state, action) => {
      state.error = action.payload; // Save error message
    },
  },
});

export const { setDataSection, setLoading, setError } = AddressReducer.actions;

export default AddressReducer.reducer;
