import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sections: {}, // Lưu dữ liệu từng section
  loading: false,
  error: null,
};

const PolicyReducer = createSlice({
  name: "policy",
  initialState,
  reducers: {
    setDataSection: (state, action) => {
      const section = action.payload.section; // Tên section
      state.sections[section] = action.payload; // Cập nhật dữ liệu section
    },
    setLoading: (state, action) => {
      state.loading = action.payload; // Cập nhật trạng thái loading
    },
    setError: (state, action) => {
      state.error = action.payload; // Lưu thông báo lỗi nếu có
    },
  },
});

export const { setDataSection, setLoading, setError } = PolicyReducer.actions;

export default PolicyReducer.reducer;
