import React, { useState } from "react";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Swiper.scss";
import { SwiperNavButtons } from "./SwiperNavButtons";
import Loading from "../Loading/Loading";

const CustomSwiper = ({ swipers, allowTouchMove }) => {
  const [current, setCurrent] = useState(0);
  const [loadedSlides, setLoadedSlides] = useState([0]); // Lưu danh sách slide đã tải

  const handleSlideChange = (swiper) => {
    const { realIndex } = swiper;
    setCurrent(realIndex);
    setLoadedSlides((prev) =>
      prev.includes(realIndex) ? prev : [...prev, realIndex]
    );
  };
  return (
    <Swiper
      modules={[Navigation]}
      slidesPerView={1}
      allowTouchMove={allowTouchMove ?? true}
      lazy={{
        loadPrevNext: false, // Không tải ảnh trước/sau
        loadOnTransitionStart: true, // Chỉ tải khi bắt đầu chuyển slide
      }}
      loop={true}
      onSlideChange={(a) => {
        handleSlideChange(a)
      }}
      spaceBetween={1}
      className="custom--swiper"
      onMouseOver={(e)=>{
        console.log("CHECK")
      }}
    >
      <SwiperNavButtons
        isShow={swipers.length > 0}
        isFirst={current === 0}
        isLast={current === swipers.length - 1}
      />
      {swipers.map((swiper, index) => (
        <SwiperSlide key={index}>
          {loadedSlides.includes(index) ? (
            <>
            {swiper}
            <div className="swiper-lazy-preloader swiper-lazy-preloader-black">
            { (
              <div className="loading-container">
                <Loading />
              </div>
            )}
            </div>
          </>
          ) : (
            <div className="swiper-lazy-preloader swiper-lazy-preloader-black">
            { (
              <div className="loading-container">
                <Loading />
              </div>
            )}
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CustomSwiper;
