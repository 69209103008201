import React from "react";

import {
  FreeMode,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../ProductCard/ProductCard";
import "./SwiperScroll.scss";

export default function SwiperScroll({ title, data }) {
  return (
    <main className="swiper-scroll">
      <div className="swiper-scroll__title">
        <h2>{title}</h2>
      </div>

      <div className="swiper-scroll__main">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={10}
          scrollbar={{ draggable: true }}
          navigation={true}
          speed={1000}
          virtual={false}
          modules={[FreeMode, Navigation, Pagination, Scrollbar, Virtual]}
          breakpoints={{
            576: {
              slidesPerView: "auto",
              freeMode: false,
              virtual: false,
            },
            768: {
              slidesPerView: 2,
              freeMode: false,
              virtual: true,
            },
            1024: {
              slidesPerView: 3,
              freeMode: true,
              virtual: true,
            },
            1440: {
              slidesPerView: 3,
              freeMode: true,
              virtual: true,
            },
            1920: {
              slidesPerView: 4,
              freeMode: true,
              virtual: true,
            },
          }}
        >
          {data?.map((dItem) => {
            return (
              <SwiperSlide key={dItem._id}>
                <ProductCard allowTouchMove={false} product={dItem} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </main>
  );
}
