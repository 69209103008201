import callApi from "../../utils/callApi";

import { OrderPath, Payment, StatusOrder } from "../../utils/enum";
import { redirectMomo } from "../reducers/momo.reducers";

export const payMomo = ({amount, orderId, redirectUrl, deposit}) => {
  const result = async (dispatch) => {
    try {
      await callApi(Payment.MOMO, "POST", {
        amount:amount,
        orderId:orderId,
        redirectUrl:redirectUrl,
        ipnUrl: process.env.REACT_APP_API_URL+OrderPath.ORDER_STATUS +`?order_id=${orderId}&status=${StatusOrder.Payment}`
      }).then((res) => {
        dispatch(redirectMomo(res.data.data.payUrl))
      }).catch(e=> {throw e});
      dispatch()
    } catch (err) {
      console.log(err);
    }
  };
  return result;
};