const ICCheck = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width, height: height , color:color}}
      viewBox={`0 0 80 80`}
      fill="currentColor"
    >
      <path d="M31.8334 58.8459L14.0518 41.0642L17.6151 37.5001L31.8334 51.7184L62.3851 21.1667L65.9484 24.7309L31.8334 58.8459Z"/>
    </svg>
  );
};
export default ICCheck;
