import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // cartLocal: { lastItem: {}, listProduct: [] },
    userDetailLocal:
      JSON.parse(localStorage.getItem("userDetailLocal"))??undefined,
};

const updateLocalStorage = (detail) => {
  localStorage.setItem("userDetailLocal", JSON.stringify({ detail }));
};

export const UserDetailLocal = createSlice({
  name: "userDetailLocal",
  initialState,
  reducers: {
    clearUserDetail: (state) => {
      updateLocalStorage(undefined);
    },
    updateUserDetail:(state, action)=>{
      updateLocalStorage(action.payload);
    }
  },
});

export const { updateUserDetail, clearUserDetail } = UserDetailLocal.actions;

export default UserDetailLocal.reducer;
