import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.scss";
import ButtonSolidMedium from "../../components/Button/ButtonSolidMedium";

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/khong-tim-thay-trang");
  }, [navigate]);

  const goBack = () => {
    navigate("/");
  };

  return (
    <div className="notfound-page">
      <section className="notfound-section">
        <div className="notfound-content">
          <div
            className="group-content"
          >
            <div className="content--block">
              <div style={{display:"flex", flexDirection:"column", gap:"8px", alignItems:"start"}}>
                <h1 className="notfound-title">404</h1>
                <h1 className="text-content">Trang không tìm thấy</h1>
                <p className="notfound-subcontent">
                  Trang bạn vừa truy cập không tồn tại hoặc đã rời đi. Hãy thử lại
                  sau nhé
                </p>
              </div>

              <ButtonSolidMedium title={"Về trang chủ"} style={{ flex: "0 0 0" }} />
            </div>
            <img id="imageNotFound" src={"./assets/images/home/image404.png"} alt="" style={{width:"100%"}}/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageNotFound;
