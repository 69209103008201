import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POLICYPATH, SectionPath } from "../../utils/enum";
import "./PolicyPage.scss";
import { getPolicySection } from "../../store/actions/policy.action";
import callApi from "../../utils/callApi";
import { Tabs } from "antd";

const PolicyPage = () => {
  const dispatch = useDispatch();
  const { sections, loading, error } = useSelector((state) => state.policy);

  // State to manage selected policy
  const [selectedPolicy, setSelectedPolicy] = useState("Chính sách bán hàng");

  useEffect(() => {
    dispatch(getPolicySection(SectionPath.HERO_POLICY));
    dispatch(getPolicySection(SectionPath.POLICY_ITEM));
  }, [dispatch]);

  useEffect(() => {
    if (sections?.policy__items?.items?.length > 0) {
      setSelectedPolicy(sections.policy__items.items[0].title);
    }
  }, [sections]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const heroPolicy = sections?.hero_policy;
  const policyItems = sections?.policy__items;

  // Get content for the selected policy
  const selectedContent = policyItems?.items.find(
    (item) => item.title === selectedPolicy
  )??policyItems?.items[0];

  return (
    <div className="policy-page">
      {/* Hero Section */}
      {heroPolicy && (
        <section className="hero-section">
          <div className="group-content">
            <h1
              className="hero-title"
              dangerouslySetInnerHTML={{
                __html: heroPolicy.title || "",
              }}
            />
            <p
              className="hero-subtitle"
              dangerouslySetInnerHTML={{
                __html: heroPolicy.content || "",
              }}
            />
          </div>
          {heroPolicy.attachments?.[0] && (
            <div className="hero-image">
              <img src={heroPolicy.attachments[0]} alt="Policy Hero" />
            </div>
          )}
        </section>
      )}

      {/* Policy Items */}
      {policyItems && (
        <section className="policy-section">
          <div className="policy-container">
            {/* Left Column: Radio Group */}
            <div className="policy-top">
              <Tabs activeKey={selectedContent.title} items={[
                ...policyItems.items?.map((item, index) => ( {
                  key: item.title,
                  label: <p
                  dangerouslySetInnerHTML={{
                    __html: item.title || "",
                  }}
                />,
                  children:  <div className="policy-right">
                  {selectedContent ? (
                    <>
                      {/* <h3
                        dangerouslySetInnerHTML={{
                          __html: selectedContent.title || "",
                        }}
                      /> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedContent.content || "",
                        }}
                      />
                      {/* Display attachments as images */}
                      {selectedContent.attachments?.length > 0 && (
                        <div className="attachments">
                          {selectedContent.attachments.map((attachment, index) => (
                            <img
                              key={`attachment-${index}`}
                              src={attachment}
                              alt={`Attachment ${index + 1}`}
                              className="attachment-image"
                            />
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <p>Không có thông tin cho chính sách được chọn.</p>
                  )}
                </div>,
                  })),
                ]}
                onChange={(key) => setSelectedPolicy(key)}
                style={{ width: "100%" }} />
            </div>
            <div className="policy-left">
              <div className="radio-group">
                {policyItems.items?.map((item, index) => (
                  <label
                    key={`policy-radio-${index}`}
                    className={`radio-item ${selectedPolicy === item.title ? "selected" : ""}`}
                  >
                    <div className="group-radio-content">
                      <img
                        src={item.icon_title}
                        alt={item.title}
                        className="icon"
                      />
                      <input
                        type="radio"
                        name="policy"
                        value={item.title}
                        checked={selectedPolicy === item.title}
                        onChange={() => setSelectedPolicy(item.title)}
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.title || "",
                        }}
                      />
                    </div>
                  </label>
                ))}
              </div>
            </div>

            {/* Right Column: Content */}
            <div className="policy-right">
              {selectedContent ? (
                <>
                  {/* <h3
                    dangerouslySetInnerHTML={{
                      __html: selectedContent.title || "",
                    }}
                  /> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: selectedContent.content || "",
                    }}
                  />
                  {/* Display attachments as images */}
                  {selectedContent.attachments?.length > 0 && (
                    <div className="attachments">
                      {selectedContent.attachments.map((attachment, index) => (
                        <img
                          key={`attachment-${index}`}
                          src={attachment}
                          alt={`Attachment ${index + 1}`}
                          className="attachment-image"
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <p>Không có thông tin cho chính sách được chọn.</p>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default PolicyPage;
