const ButtonSmall = ({ icon, title, disabled, onClick, type }) => {
  return (
    <button className="btn--text small" disabled={disabled} onClick={onClick} type={type}>
      {icon}
      <span className="btn--text-title hover-underline-animation">{title}</span>
    </button>
  );
};

export default ButtonSmall;
