import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionPath } from "../../utils/enum";
import "./AddressPage.scss";
import { getDataSection } from "../../store/actions/address.actions";

const AddressPage = () => {
  const dispatch = useDispatch();
  const { sections, loading, error } = useSelector((state) => state.address);

  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    dispatch(getDataSection(SectionPath.HERO_LOCATION));
    dispatch(getDataSection(SectionPath.LOCATIONS));
  }, [dispatch]);

  useEffect(() => {
    // Khi dữ liệu được tải xong, đặt địa chỉ đầu tiên làm mặc định
    if (sections?.locations?.sub_sections?.length > 0 && !selectedAddress) {
      setSelectedAddress(sections.locations.sub_sections[0]);
    }
  }, [sections, selectedAddress]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const heroLocation = sections?.hero_location;
  const locations = sections?.locations;

  const handleAddressSelect = (location) => {
    setSelectedAddress(location);
  };

  const getGoogleMapUrl = (address) => {
    return `https://www.google.com/maps?q=${encodeURIComponent(address)}&output=embed`;
  };

  return (
    <div className="address-page">
      {/* Hero Section */}
      {heroLocation && <section className="hero-section">
            <img src={heroLocation.attachments[0]} alt="" />
            <div className="layer-blur"></div>
            <div className={"hero-section--content"}>
                <h1 className={"hero-section--content--title"} dangerouslySetInnerHTML={{
                    __html: heroLocation.title || "",
                }}></h1>
                <h3 className={"hero-section--content--content"} dangerouslySetInnerHTML={{
                    __html: heroLocation.content || "",
                }}></h3>
            </div>
        </section>}
      {/* {heroLocation && (
        <section className="hero-section">
          <div className="group-content">
            <h1
              className="hero-title"
              dangerouslySetInnerHTML={{ __html: heroLocation.title }}
            />
            <p
              className="hero-subtitle"
              dangerouslySetInnerHTML={{ __html: heroLocation.content }}
            />
          </div>
          {heroLocation.attachments?.[0] && (
            <div className="hero-image">
              <img src={heroLocation.attachments[0]} alt="Hero Location" />
            </div>
          )}
        </section>
      )} */}

      {/* Locations Section */}
      {locations && (
        <section className="locations-section">
          <div className="locations-container">
            <div className="locations-left">
              <div className="radio-group">
                {locations.sub_sections.map((location, index) => (
                  <label
                    key={`locations-radio-${index}`}
                    className={`radio-item ${
                      selectedAddress?.section === location.section ? "selected" : ""
                    }`}
                  >
                    <div className="group-radio-content">
                      <input
                        type="radio"
                        name="locations"
                        value={location.section}
                        checked={selectedAddress?.section === location.section}
                        onChange={() => handleAddressSelect(location)}
                      />

                      <div className="location-info">
                        <div className="group-icon d-flex flex-col">
                        <img
                          src={location.icon_title}
                          alt="Location Icon"
                          className="location-icon"
                        />
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: location.title,
                          }}
                        />
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: location.content,
                          }}
                        />
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="locations-right">
              {/* Hiển thị Google Maps hoặc hình ảnh */}
              {selectedAddress && selectedAddress.content ? (
                <div className="location-map">
                  <iframe
                    src={getGoogleMapUrl("55 Đinh Thị Thi (KDC Vạn Phúc), QL13, Thủ Đức, HCM")}
                    width="100%"
                    height="400"
                    frameBorder="0"
                    allowFullScreen
                    title="Google Map"
                  ></iframe>
                </div>
              ) : (
                <div className="placeholder">
                  <p>Vui lòng chọn một địa chỉ để xem chi tiết</p>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default AddressPage;
