// components/AboutUsPage.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionPath } from "../../utils/enum";
import { getDataSection } from "../../store/actions/aboutUs.actions";
import "./AboutUsPage.scss"
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sections, loading, error } = useSelector((state) => state.aboutUs);

  useEffect(() => {
    dispatch(getDataSection(SectionPath.HERO_ABOUT_US));
    dispatch(getDataSection(SectionPath.ABOUT_US));
    dispatch(getDataSection(SectionPath.FEATURE_ABOUT_US));
    dispatch(getDataSection(SectionPath.WOOD_WORKSHOP));
    dispatch(getDataSection(SectionPath.HERO_POLICY));
    dispatch(getDataSection(SectionPath.POLICY_ITEM));
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="about-us-page">
      {/* Hero Section */}
      {sections.hero_about_us && <section className="hero-section">
        <img src={sections.hero_about_us.attachments[0]} alt="" />
        <div className="layer-blur"></div>
        <div className={"hero-section--content"}>
          <h1 className={"hero-section--content--title"} dangerouslySetInnerHTML={{
            __html: sections.hero_about_us.title || "",
          }}></h1>
          <h3 className={"hero-section--content--content"} dangerouslySetInnerHTML={{
            __html: sections.hero_about_us.content || "",
          }}></h3>
        </div>
      </section>}

      {/* About Section */}
      {sections.about_us && (
        <section className="about-section">
          <h2
            className="about-title"
            dangerouslySetInnerHTML={{ __html: sections.about_us.title }}
          />
          <p
            className="about-content"
            dangerouslySetInnerHTML={{ __html: sections.about_us.content }}
          />
          <div className="about-image">
            <img
              src={sections.about_us.attachments[0]}
              alt="About Us"
            />
          </div>
        </section>
      )}

      {/* Feature Section */}
      {sections.feature_about_us && (
        <section className="feature-section">
          <div className="feature-image">
            <img
              src={sections.feature_about_us.attachments[0]}
              alt="Feature"
            />
          </div>
          <div className="group-content">

            <h2
              className="feature-title"
              dangerouslySetInnerHTML={{ __html: sections.feature_about_us.title }}
            />
            <p
              className="feature-content"
              dangerouslySetInnerHTML={{ __html: sections.feature_about_us.content }}
            />

            <div className="feature-items">
              {sections.feature_about_us.sub_sections.map((item) => (
                <div key={item._id} className="feature-item" onClick={() => navigate(item.link_button)}>
                  <img src={item.icon_title} alt={item.title} className="icon" />
                  <h3
                    className="item-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                </div>
              ))}
            </div>

            {sections.feature_about_us.content_button && (
              <a
                href={sections.feature_about_us.link_button}
                className="feature-button"
                dangerouslySetInnerHTML={{
                  __html: sections.feature_about_us.content_button,
                }}
              />
            )}
          </div>
        </section>
      )}

      {/* Wood Workshop Section */}
      {sections.wood_workshop && (
        <section className="wood-workshop-section">
          <h2
            className="workshop-title"
            dangerouslySetInnerHTML={{ __html: sections.wood_workshop.title }}
          />
          <p
            className="workshop-content"
            dangerouslySetInnerHTML={{ __html: sections.wood_workshop.content }}
          />

          {/* <div className="workshop-images">
            {sections.wood_workshop.attachments.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Workshop ${index + 1}`}
                className="workshop-image"
              />
            ))}
          </div> */}
          <div className="categories__swiper">
            <Swiper
              // freeMode={isMobile ? false : true}
              lazy="true"
              slidesPerView={window.innerWidth<577?1:3}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              navigation={true}
              speed={1000}
              freeMode={true}
              modules={[FreeMode, Navigation, Pagination, Scrollbar]}
            >
              {sections.wood_workshop.attachments.map((sItem, idx) => {
                return (
                  <SwiperSlide
                    key={idx}
                  >
                    <img
                      style={{ minHeight: 300 }}
                      src={sItem}
                      alt="category"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}
    </div>
  );
};

export default AboutUsPage;
