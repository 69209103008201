import { useLocation, useNavigate } from "react-router-dom";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import ICCheck from "../../assets/icons/ICCheck";
import ButtonSolidMedium from "../../components/Button/ButtonSolidMedium";
import "./PaymentPage.scss";
import { onePayVerifySecureHash } from "../../utils/payone/VerifyVpcSecureHash.js";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getOrderAction, updateOderAction } from "../../store/actions/order.action.js";
import Loading from "../../components/Loading/Loading.jsx";
import { EmailPeriod, StatusOrder, TypePayment } from "../../utils/enum.js";
import { removeInCart } from "../../store/reducers/cartLocal.reducers.js";
import { fetchRecentlyProductsAction } from "../../store/actions/recentlyProduct.action.js";
import { EmailJS } from "../../components/Email/index.js";
import { clearOrder, updateOrder } from "../../store/reducers/order.reducers.js";
import { formatToVND } from "../../utils/currency.js";
import { stringToUrl } from "../../utils/url.utils.js";

const PaymentPage = () => {
  const { recentlyProduct, localIds } = useSelector(
    (state) => state.recentlyProduct
  );
  const { userDetailLocal } = useSelector((state) => state.userDetailLocal);
  const { dataProductCategory } = useSelector(
      (state) => state.dataProductCategory
    );

  const [paramsObject, setParamsObject] = useState();
  const [verified, setVerified] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {order} = useSelector((state)=>state.order)


  const handleUpdateOrder = async () => {
    dispatch(updateOderAction({
      code: paramsObject?.vpc_OrderInfo,
      status: StatusOrder.PAID,
      amount_paid: Number(paramsObject?.vpc_Amount??paramsObject?.amount)/100,
    }))
      .then((res) => {
        if(order?.status===StatusOrder.WAIT_PAYMENT){
         
          dispatch(getOrderAction({id:order?._id})).then(res=>{
            const order =res.data.data;
              new EmailJS().sendEmail({
                orderId: order?.code,
                title:"Đặt hàng thành công",
                subtitle:"Cảm ơn bạn đã mua sắm tại Là Nhà Decor, hy vọng bạn đã có những tốt nhất tại website của chúng tôi.",
                user_email: userDetailLocal.detail.email,
                user_name: userDetailLocal.detail.firstName,
                user_phone: order?.phone,
                user_address: order?.address?.split("/").join(" /"),
                date_receive:order?.delivery_date,
                list_product:`<tr>
    <td align="left"
        style="padding:0;Margin:0;padding-top:24px;padding-bottom:24px">
        <table cellpadding="0" cellspacing="0" width="100%" role="none"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
            <tr>
                <td align="left" style="padding:0;Margin:0;width:600px">
                    <table role="presentation" cellpadding="0" cellspacing="0"
                        width="100%"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                            <td align="left" class="es-text-3832"
                                style="padding:0;Margin:0">
                                <p class="es-text-mobile-size-24"
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:36px;letter-spacing:0;color:#333333;font-size:24px">
                                    <strong>Sản phẩm</strong></p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </td>
</tr>${order?.items?.map((item)=>`<tr>
    <td align="left" bgcolor="#F7F7F7"
        style="padding:0;Margin:0;padding-top:24px;background-color:#F7F7F7">
        <!--[if mso]><table style="width:600px" cellpadding="0" cellspacing="0"><tr><td style="width:288px" valign="top"><![endif]-->
        <table cellpadding="0" cellspacing="0" align="left" class="es-left"
            role="none"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
            <tr>
                <td align="left" class="es-m-p20b"
                    style="padding:0;Margin:0;width:288px">
                    <table cellspacing="0" width="100%" role="presentation"
                        cellpadding="0" bgcolor="#F7F7F7"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#F7F7F7">
                        <tr>
                            <td align="center"
                                style="padding:0;Margin:0;padding-top:16px;padding-left:16px;padding-bottom:16px;font-size:0">
                                <img alt="" width="186"
                                    src="${item.img}"
                                    height="80"
                                    style="display:block;font-size:14px;border:0;outline:none;text-decoration:none">
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <!--[if mso]></td><td style="width:24px"></td><td style="width:288px" valign="top"><![endif]-->
        <table cellspacing="0" align="right" cellpadding="0" class="es-right"
            role="none"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
            <tr>
                <td align="left" style="padding:0;Margin:0;width:288px">
                    <table width="100%" role="presentation" cellpadding="0"
                        cellspacing="0"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                            <td align="left" class="es-text-1885"
                                style="padding:0;Margin:0">
                                <p class="es-text-mobile-size-24"
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:36px;letter-spacing:0;color:#333333;font-size:24px">
                                    <strong>${item.product_name}</strong></p>
                            </td>
                        </tr>
                        ${item.features.map((feature)=>`<tr>
                            <td align="left"
                                style="padding:0;Margin:0;padding-top:16px">
                                <p
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">
                                    ${feature}</p>
                            </td>
                        </tr>`)}
                        
                        <tr>
                            <td align="left" class="es-text-7327"
                                style="padding:0;Margin:0;padding-top:16px;padding-bottom:16px">
                                <p class="es-text-mobile-size-16"
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:24px;letter-spacing:0;color:#333333;font-size:16px">
                                    <strong>${formatToVND(Number(item.price_original))}</strong></p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table><!--[if mso]></td></tr></table><![endif]-->
    </td>
</tr>`)}`,
                method:order?.type === TypePayment.DEPOSIT?"Đặt cọc 50%":order?.type === TypePayment.FULL_PAYMENT?"Thanh toán toàn bộ":order?.type === TypePayment.INSTALLMENT?"Trả góp":undefined,
                card:"",
                predict:formatToVND(order?.amount),
                fee_shipping:"Liên hệ sau",
                discount:formatToVND(Number(order?.discount)),
                total:formatToVND(order?.amount),
                paid:formatToVND(order?.amount_paid),
                remained_money:formatToVND(Number(order?.amount)-Number(order?.amount_paid)),
                note:order?.type === TypePayment.DEPOSIT?`<table cellspacing="0" width="100%" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td align="left" style="padding:0;Margin:0;width:600px"><table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td align="left" style="Margin:0;padding-top:16px;padding-left:16px;padding-right:16px;padding-bottom:4px;font-size:0"><img src="https://fpxkgde.stripocdn.email/content/guids/CABINET_d01053b71b0a717c8a1512d40326f247d604dcc4a3286b4a70502b89add03900/images/error.png" alt="" width="24" height="24" style="display:block;font-size:14px;border:0;outline:none;text-decoration:none"></td></tr> <tr>
<td align="left" style="padding:0;Margin:0;padding-left:16px;padding-right:16px;padding-bottom:16px"><p style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px"><strong>Lưu ý:</strong> thanh toán phần còn lại sau khi Là Nhà Decor gửi hình ảnh &amp; video sản phẩm đã được hoàn thiện</p></td></tr></table>`:undefined,
                fromName: "Là Nhà",
                order_period:`<table cellpadding="0" cellspacing="0" align="left" class="es-left">
                                                    <tbody>
                                                      <tr>
                                                        <td width="40" align="left" class="esd-container-frame es-p40l">
                                                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation">
                                                            <tbody>
                                                              <tr>
                                                                <td align="center" class="esd-block-image" style="font-size: 0">
                                                                  <a target="_blank">
                                                                    <img src="https://fpxkgde.stripocdn.email/content/guids/CABINET_d01053b71b0a717c8a1512d40326f247d604dcc4a3286b4a70502b89add03900/images/check.png" alt="" width="25">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <!--[if mso]></td><td width="248" valign="top"><![endif]-->
                                                  <table cellpadding="0" cellspacing="0" align="right" class="es-right">
                                                    <tbody>
                                                      <tr>
                                                        <td width="248" align="left" class="esd-container-frame">
                                                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation">
                                                            <tbody>
                                                              <tr>
                                                              <td align="center" class="esd-block-image" style="font-size: 0">
                                                                  <a target="_blank">
                                                                    <img src="https://fpxkgde.stripocdn.email/content/guids/CABINET_d01053b71b0a717c8a1512d40326f247d604dcc4a3286b4a70502b89add03900/images/check.png" alt="" width="25">
                                                                  </a>
                                                                </td>
                                                                <td align="left" class="esd-block-text" style="font-family:arial, 'helvetica neue', helvetica, sans-serif">
                                                                  <p>
                                                                    <strong>Xác nhận đơn hàng</strong> - 05/01/2025
                                                                  </p>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>`
              });
              dispatch(clearOrder());

            }) 
          };
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = "Tình trạng đơn hàng - Là Nhà Decor";
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const temp = {};
    queryParams.forEach((value, key) => {
      temp[key] = value;
    });
    setParamsObject(temp);
    const url = window.location.href;
    setVerified(
      onePayVerifySecureHash(
        url,
        process.env.REACT_APP_MERCHANT_PAYNOW_HASH_CODE
      )||temp?.partnerCode==="MOMO"
    );
    dispatch(removeInCart());
  }, [location]);

  useEffect(() => {
    if (verified) {
      handleUpdateOrder();
    }
  }, [verified]);

  useEffect(() => {
    if (localIds) {
      dispatch(fetchRecentlyProductsAction(localIds));
    }
  }, [localIds]);

  if (!paramsObject || loading) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          alignSelf: "stretch",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="cart--page">
      {
        <>
          {
            <section className="cart--page--category">
              <div className="cart--page--category--container">
                {(paramsObject?.vpc_TxnResponseCode === "0" ||
                  paramsObject?.resultCode === "0") && (
                  <ICCheck width={80} height={80} color={"#4BD64B"} />
                )}
                <div className="cart--page--category--container--content">
                  <div className="cart--page--category--container--content--title">
                    <span className="cart--page--category--container--content--title">
                      {paramsObject?.vpc_TxnResponseCode === "0" ||
                      paramsObject?.resultCode === "0"
                        ? "Đặt hàng thành công"
                        : "Đặt hàng thất bại"}
                    </span>
                  </div>
                  <div className="cart--page--category--container--content--description">
                    <span className="cart--page--category--container--content--description">
                      {(paramsObject?.vpc_TxnResponseCode === "0" ||
                        paramsObject?.resultCode === "0") && (
                        <>
                          Nhân viên Là Nhà Decor sẽ liện hệ lại với bạn để xác
                          nhận đơn hàng và hướng dẫn các bước tiếp theo. Mã đơn
                          hàng của bạn là{" "}
                          <b>
                            {order?.code}
                          </b>
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <ButtonSolidMedium
                  title={"Tiếp tục mua sắm"}
                  preIcon={<ICChevronRight width={24} height={24} />}
                  onClick={() => {
                    const allProduct = dataProductCategory?.categoryFilter?.find((category) =>
                      category?.title?.toLowerCase().includes("tất cả"))
                    navigate(`/sofa/${stringToUrl(allProduct?.title)}`)
                  }}
                />
              </div>
            </section>
          }
        </>
      }
      <main className="swiper-scroll">
        <div className="swiper-scroll__title">
          <h2>Bạn đã xem</h2>
        </div>

        <div className="swiper-scroll__main">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            navigation={true}
            speed={1250}
            modules={[FreeMode, Navigation, Pagination, Scrollbar]}
            breakpoints={{
              576: {
                slidesPerView: 1,
                freeMode: false,
              },
              768: {
                slidesPerView: 2,
                freeMode: false,
              },
              1024: {
                slidesPerView: 3,
                freeMode: true,
              },
              1440: {
                slidesPerView: 3,
                freeMode: true,
              },
              1920: {
                slidesPerView: 4,
                freeMode: true,
              },
            }}
          >
            {recentlyProduct?.map((dItem) => {
              return (
                <SwiperSlide key={dItem._id}>
                  <ProductCard allowTouchMove={false} product={dItem} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </main>
    </div>
  );
};

export default PaymentPage;
