const CryptoJS = require("crypto-js");


function sortObj(obj) {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

function generateStringToHash(paramSorted) {
  let stringToHash = "";
  for (const key in paramSorted) {
    let value = paramSorted[key];
    let pref4 = key.substring(0, 4);
    let pref5 = key.substring(0, 5);
    if (pref4 === "vpc_" || pref5 === "user_") {
      if (key !== "vpc_SecureHash" && key !== "vpc_SecureHashType") {
        if (value?.length && value.length > 0) {
          if (stringToHash?.length && stringToHash.length > 0) {
            stringToHash = stringToHash + "&";
          }
          stringToHash = stringToHash + key + "=" + value;
        }
      }
    }
  }
  return stringToHash;
}

function genSecureHash(stringToHash, merHashCode) {
  try{
    let merHashHex = CryptoJS.enc.Hex.parse(merHashCode);
    let keyHash = CryptoJS.HmacSHA256(stringToHash, merHashHex);
    let keyHashHex = CryptoJS.enc.Hex.stringify(keyHash).toUpperCase();
    return keyHashHex;
  }catch(e){console.log(e)}
}

async function sendHttpsGet(url) {
  console.log("URL: " + url);
  try {
    window.location.href=url
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
      } else {
      }
    }
}

function extractValueByKey(key, array) {
  for (let i = 0; i < array.length; i += 2) {
    if (array[i] === key) {
      return array[i + 1];
    }
  }
  return null;
}

async function getIPAddress() {
  while(true){
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip
    } catch (error) {
      console.error("Error fetching IP address");
    }
  }
  // try {
  //     const response = await fetch("https://api.ipify.org?format=json");
  //     const data = await response.json();
  //     return data.ip
  // } catch (error) {
  //     console.error("Error fetching IP address:", error);
  // }
}

module.exports = {
  sortObj,
  generateStringToHash,
  genSecureHash,
  sendHttpsGet,
  extractValueByKey,
  getIPAddress,
};
