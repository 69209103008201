import ProductCard from "../../components/ProductCard/ProductCard";

import ICSort from "../../assets/icons/ICSort";

import "./ProductCategory.scss";
import "./MobileFilter.scss";

import ButtonDropdown from "../../components/ButtonDropdown/ButtonDropdown";
import PaginationBasic from "../../components/Pagination/PaginationBasic";
import React, { useEffect, useState } from "react";
import ICDropdown from "../../assets/icons/ICDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataProduct,
  getDataCategoryFilter,
  getDataSimilarColorFilter,
  getDataMaterialFilter,
  getDataStyleFilter,
  getDataShapeFilter,
} from "../../store/actions/productCategory.action";
import MobileFilter from "./MobileFilter";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonDropdownPrice from "./ButtonDropdownPrice";
import ButtonDropdownColor from "./ButtonDropdownColor";
import ButtonDropdownMaterial from "./ButtonDropdownMaterial";
import ButtonDropdownStyle from "./ButtonDropdownStyle";
import ButtonDropdownShape from "./ButtonDropdownShape";
import ButtonSmall from "../../components/Button/ButtonSmall";
import ICClose from "../../assets/icons/ICClose";
import { stringToUrl } from "../../utils/url.utils";
import { TypeProduct } from "../../utils/enum";

const optionSort = [
  { title: "Cao đến thấp", value: "price__dec" },
  { title: "Thấp đến cao", value: "price__inc" },
  { title: "Tên", value: "name" },
];

const ProductCategory = React.memo(() => {
  let {type} = useParams();
  let minStep = 100000;
  let minRange = 0;
  let maxRange = 100000000;

  const [colorArray, setColorArray] = useState([]);
  const [materialArray, setMaterialArray] = useState([]);
  const [styleArray, setStyleArray] = useState([]);
  const [shapeArray, setShapeArray] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [sort, setSort] = useState("price__inc");
  const navigate = useNavigate();
  

  const [searchParams, setSearchParams] = useSearchParams();

  // const categoryFilterString = searchParams.get("_id");
  const colorFilterString = searchParams.get("color");
  const materialFilterString = searchParams.get("material");
  const styleFilterString = searchParams.get("style");
  const shapeFilterString = searchParams.get("shape");
  const fromFilterString = searchParams.get("from");
  const toFilterString = searchParams.get("to");
  const offsetFilterString = searchParams.get("offset");
  const limitFilterString = searchParams.get("limit");

  const setOffsetFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null)
        prevParams.set("offset", value.toString());
      else {
        prevParams.delete("offset");
      }
      return prevParams;
    });
  };
  const setLimitFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null)
        prevParams.set("limit", value.toString());
      else {
        prevParams.delete("limit");
      }
      return prevParams;
    });
  };

  // const setCategoryFilter = (value) => {
  //   setSearchParams((prevParams) => {
  //     if (value !== undefined) prevParams.set("_id", value.toString());
  //     else {
  //       prevParams.delete("_id");
  //     }
  //     return prevParams;
  //   });
  // };

  const setColorFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value.length)
        prevParams.set("color", JSON.stringify(value));
      else {
        prevParams.delete("color");
      }
      return prevParams;
    });
  };

  const setMaterialFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value.length)
        prevParams.set("material", JSON.stringify(value));
      else {
        prevParams.delete("material");
      }
      return prevParams;
    });
  };

  const setStyleFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value.length)
        prevParams.set("style", JSON.stringify(value));
      else {
        prevParams.delete("style");
      }
      return prevParams;
    });
  };

  const setShapeFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value.length)
        prevParams.set("shape", JSON.stringify(value));
      else {
        prevParams.delete("shape");
      }
      return prevParams;
    });
  };

  const setFromFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null)
        prevParams.set("from", value.toString());
      else {
        prevParams.delete("from");
      }
      return prevParams;
    });
  };

  const setToFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null)
        prevParams.set("to", value.toString());
      else {
        prevParams.delete("to");
      }
      return prevParams;
    });
  };

  const dispatch = useDispatch();

  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  useEffect(() => {
    const subHeader = document.querySelector(".product--category--header");
    const filter = document.querySelector(".product--list--title");
    if (subHeader && filter) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 450) {
          subHeader.classList.add("show");
          filter.classList.add("hidden");
        } else {
          subHeader.classList.remove("show");
          filter.classList.remove("hidden");
        }
      });
      return () =>
        window.removeEventListener("scroll", () => {
          if (window.scrollY > 450) {
            subHeader.style.opacity = 1;
            subHeader.style.zIndex = 100;
          } else {
            subHeader.style.opacity = 0;
            subHeader.style.zIndex = -1;
          }
        });
    }
  }, []);

  useEffect(() => {
    dispatch(getDataCategoryFilter());
    dispatch(getDataSimilarColorFilter());
    dispatch(getDataMaterialFilter());
    dispatch(getDataStyleFilter());
    dispatch(getDataShapeFilter());
  }, []);

  useEffect(() => {
    try {
      setColorArray(JSON.parse(colorFilterString) ?? []);
    } catch (e) {
      setColorArray([]);
    }
  }, [colorFilterString]);

  useEffect(() => {
    try {
      setMaterialArray(JSON.parse(materialFilterString) ?? []);
    } catch (e) {
      setMaterialArray([]);
    }
  }, [materialFilterString]);

  useEffect(() => {
    try {
      setStyleArray(JSON.parse(styleFilterString) ?? []);
    } catch (e) {
      setStyleArray([]);
    }
  }, [styleFilterString]);

  useEffect(() => {
    try {
      setShapeArray(JSON.parse(shapeFilterString) ?? []);
    } catch (e) {
      setShapeArray([]);
    }
  }, [shapeFilterString]);

  useEffect(() => {
    dispatch(
      getDataProduct({
        category: categoryId,
        fromPrice: fromFilterString,
        toPrice: toFilterString,
        colors: colorArray,
        materials: materialArray,
        limit: limitFilterString ?? 15,
        offset: offsetFilterString,
        sort: sort,
        tag: [...styleArray, ...shapeArray],
      })
    );
  }, [
    fromFilterString,
    toFilterString,
    categoryId,
    colorArray,
    materialArray,
    limitFilterString,
    offsetFilterString,
    sort,
    styleArray,
    shapeArray,
  ]);

  useEffect(() => {
    const category = dataProductCategory?.categoryFilter?.filter(
      (category) => category._id === categoryId
    );

    if (category && category?.length !== 0) {
      document.title = `${category[0]?.title?.toUpperCase()} - Là Nhà Decor`;
    }
  }, [categoryId, dataProductCategory]);

  useEffect(() => {
    setCategoryId(dataProductCategory?.categoryFilter?.find((category) =>stringToUrl(category?.title) === type)?._id);
  }, [dataProductCategory?.categoryFilter, type]);

  return (
    <section className="product--category">
      <div className="product--category--header">
        <div className="product--category--header-sub">
          <div className="header--filter">
            <div className="header--filter-title">Bộ lọc</div>
            <div className="header--filter-item">
              <ButtonDropdownPrice
                id={"priceFilterHeader"}
                minStep={minStep}
                minRange={minRange}
                maxRange={maxRange}
                minValue={fromFilterString}
                maxValue={toFilterString}
                setFromFilter={setFromFilter}
                setToFilter={setToFilter}
                fromFilter={fromFilterString}
                toFilter={toFilterString}
              />
              <ButtonDropdownColor
                id={"colorFilterHeader"}
                colorFilter={colorArray}
                setColorFilter={setColorFilter}
              />
              <ButtonDropdownMaterial
                id={"materialFilterHeader"}
                materialFilter={materialArray}
                setMaterialFilter={setMaterialFilter}
              />
              <ButtonDropdownStyle
                id={"styleFilterHeader"}
                styleFilter={styleArray}
                setStyleFilter={setStyleFilter}
              />
              <ButtonDropdownShape
                id={"shapeFilterHeader"}
                shapeFilter={shapeArray}
                setShapeFilter={setShapeFilter}
              />
            </div>
          </div>
          <ButtonDropdown
            title={optionSort.find((option) => option.value === sort)?.title}
            id={"SortHeader"}
            isHidden={true}
            isSmall={true}
            style={{ border: "none", width: "151px" }}
            posIcon={<ICSort width={16} height={16} />}
            container={
              <div className="list--option--sort">
                {optionSort.map((option, i) => (
                  <div
                    key={i}
                    className={`option--sort ${
                      option.value === sort && "active"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSort(option.value);
                    }}
                  >
                    {option.title}
                  </div>
                ))}
              </div>
            }
          />
        </div>
      </div>
      <div className="product--category--container">
        <div className="product--category--container--content">
          <div className="product--category--container--content-title">
            <span>Sofa</span>
          </div>
          <div className="product--category--container--content-subtitle">
            <span>
              Thiết kế công thái học, kích thước được nghiên cứu chuẩn size
              người Việt
            </span>
          </div>
        </div>
        <div className="product--category--container--filter">
          {dataProductCategory?.categoryFilter?.map((category, i) => (
            <div
              className="product--category--container--filter--selection"
              key={i}
              id={category._id}
              onClick={(e) => {
                e.stopPropagation();
                // setCategoryFilter(category._id);
                navigate(`/sofa/${stringToUrl(category?.title)}`)
              }}
            >
              <div className="product--category--container--filter--selection-image">
                <img src={category.icon} alt="category icon"  onContextMenu={(e) => e.preventDefault()} />
              </div>
              <div
                className={`product--category--container--filter--selection-description ${
                  categoryId === category._id ? "active" : ""
                }`}
              >
                <span>{category.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="product--list">
        <div className="product--list--title">
          <div className="product--list--title--filter">
            <div className="product--list--title--filter-title">
              <span>Bộ lọc</span>
            </div>
            <div className="mobile--product--list--title--filter-item">
              <ButtonDropdown
                isTranslateX={true}
                title={"Bộ lọc"}
                badge={
                  (fromFilterString !== null && toFilterString !== null
                    ? 1
                    : 0) +
                    (colorArray.length ? colorArray.length : 0) +
                    (shapeArray.length ? shapeArray.length : 0) +
                    (styleArray.length ? styleArray.length : 0) +
                    (materialArray.length ? materialArray.length : 0) ??
                  undefined
                }
                id={"filterBodyMobile"}
                container={
                  <MobileFilter
                    id={"filterBodyMobile"}
                    minValue={fromFilterString}
                    maxValue={toFilterString}
                    minRange={minRange}
                    maxRange={maxRange}
                    minStep={minStep}
                    setFromFilter={setFromFilter}
                    setToFilter={setToFilter}
                    materialFilter={materialArray}
                    setMaterialFilter={setMaterialFilter}
                    colorFilter={colorArray}
                    setColorFilter={setColorFilter}
                    styleFilter={styleArray}
                    setStyleFilter={setStyleFilter}
                    shapeFilter={shapeArray}
                    setShapeFilter={setShapeFilter}
                  />
                }
              />
            </div>
            <div className="product--list--title--filter-item">
              <ButtonDropdownPrice
                id={"priceFilter"}
                minStep={minStep}
                minRange={minRange}
                maxRange={maxRange}
                minValue={fromFilterString}
                maxValue={toFilterString}
                setFromFilter={setFromFilter}
                setToFilter={setToFilter}
                fromFilter={fromFilterString}
                toFilter={toFilterString}
              />
              <ButtonDropdownColor
                id={"colorFilter"}
                colorFilter={colorArray}
                setColorFilter={setColorFilter}
              />
              <ButtonDropdownMaterial
                id={"materialFilter"}
                materialFilter={materialArray}
                setMaterialFilter={setMaterialFilter}
              />
              <ButtonDropdownStyle
                id={"styleFilter"}
                styleFilter={styleArray}
                setStyleFilter={setStyleFilter}
              />
              <ButtonDropdownShape
                id={"shapeFilter"}
                shapeFilter={shapeArray}
                setShapeFilter={setShapeFilter}
              />
              {fromFilterString ||
              toFilterString ||
              colorFilterString ||
              materialFilterString ||
              shapeFilterString ||
              styleFilterString ? (
                <ButtonSmall
                  title={"Xóa bộ lọc"}
                  icon={<ICClose width={20} height={20} />}
                  onClick={() => {
                    setFromFilter(undefined);
                    setToFilter(undefined);
                    setColorFilter([]);
                    setMaterialFilter([]);
                    setShapeFilter([]);
                    setStyleFilter([]);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="product--list--right">
            <div className="product--list--right-pagination">
              <span>{dataProductCategory?.products?.length ?? 0} sản phẩm</span>
            </div>
            <div className="product--list--right-sort">
              {/* button dropdown */}
              <ButtonDropdown
                title={
                  optionSort.find((option) => option.value === sort)?.title
                }
                id={"SORT"}
                isHidden={true}
                isSmall={true}
                style={{ border: "none", width: "151px" }}
                posIcon={<ICSort width={16} height={16} />}
                container={
                  <div className="list--option--sort">
                    {optionSort.map((option, i) => (
                      <div
                        key={i}
                        className={`option--sort ${
                          option.value === sort && "active"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSort(option.value);
                        }}
                      >
                        {option.title}
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <div className="product--list--container">
          {dataProductCategory?.products?.map((product, i) => (
            <div className="product--list--container-item" key={i}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </div>
      <div className="product--pagination">
        <PaginationBasic
          total={Math.ceil((dataProductCategory?.totalProducts ?? 0) / 15)}
          limit={15}
          setLimit={setLimitFilter}
          offset={Number(offsetFilterString) ?? 0}
          setOffset={setOffsetFilter}
        />
      </div>
    </section>
  );
});

export default ProductCategory;
