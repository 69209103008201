import { useParams } from "react-router-dom";
import ButtonSmall from "../../components/Button/ButtonSmall";
import ProductCard from "../../components/ProductCard/ProductCard";
import Tag from "../../components/Tag/Tag";
import "./BlogDetailPage.scss"
import { useEffect } from "react";
import { getBlogAction } from "../../store/actions/blog.action";
import { useDispatch, useSelector } from "react-redux";

const BlogDetailPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { blog } = useSelector((state) => state.dataBlogPage);

    useEffect(() => {
        dispatch(getBlogAction(id));
    }, [id])

    return (<div className="blog--detail">
        <ButtonSmall title="Trở về góc cảm hứng" icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M11.6685 14.7115L6.95703 10L11.6685 5.28857L12.5466 6.1667L8.71328 10L12.5466 13.8334L11.6685 14.7115Z" fill="#121212" />
        </svg>} onClick={() => window.history.back()} />
        <div className="blog--detail--content">
            <div className="blog--detail--content--left">
                <div className="blog--detail--content--left--title">
                    <h1>{blog.title}</h1>
                    <p>{blog.summary}</p>
                    <img src={blog.img} alt="" />
                </div>
                <div className="blog--detail--content--left--body" dangerouslySetInnerHTML={{
                    __html: blog.blog,
                }}>
                </div>
                <div className="blog--detail--content--left--tag">
                    {blog.tags && blog.tags.map((tag, index) => <Tag title={tag} key={index}/>)}
                </div>
            </div>
            <div className="blog--detail--content--right">
                <ProductCard product={{}} />
            </div>
        </div>
    </div>);
}

export default BlogDetailPage;