import { useSelector } from "react-redux";
import ICChevronDown from "../../assets/icons/ICChevronDown";
import ICClose from "../../assets/icons/ICClose";
import ButtonIconLarge from "../../components/Button/ButtonIconLarge";
import ButtonOutlineMedium from "../../components/Button/ButtonOutlineMedium";
import ButtonSolidMedium from "../../components/Button/ButtonSolidMedium";
import ChipSuggest32 from "../../components/Chip/ChipSuggest32";
import ChipSuggestIcon32 from "../../components/Chip/ChipSuggestIcon32";
import InputDualRange from "../../components/Input/InputDualRange";
import { formatToVND } from "../../utils/currency";
import { useEffect, useState } from "react";

const MobileFilter = ({
  id,
  minStep,
  minValue,
  maxValue,
  minRange,
  maxRange,
  setFromFilter,
  setToFilter,
  colorFilter,
  setColorFilter,
  materialFilter,
  setMaterialFilter,
  styleFilter,
  setStyleFilter,
  shapeFilter,
  setShapeFilter,
}) => {
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const [tempColor, setTempColor] = useState([]);
  const [tempMaterial, setTempMaterial] = useState([]);
  const [tempStyle, setTempStyle] = useState([]);
  const [tempShape, setTempShape] = useState([]);
  const [tempMin, setTempMin] = useState();
  const [tempMax, setTempMax] = useState();

  const closeModal = () => {
    const dropdown = document.getElementById(id + "Dropdown-Container");
    if (dropdown) {
      dropdown.classList.add("shrink");
    }
  };

  useEffect(() => {
    setTempColor(colorFilter);
    setTempMaterial(materialFilter);
    setTempStyle(styleFilter);
    setTempShape(shapeFilter);
    setTempMin(minValue);
    setTempMax(maxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorFilter, materialFilter, minValue, maxValue, styleFilter, shapeFilter]);
  return (
    <div className="mobile--filter--container">
      <div className="mobile--filter--container--title">
        <span>Bộ lọc</span>
        <ButtonIconLarge
          icon={<ICClose width={24} height={24} />}
          id={id + "Close"}
          onClick={() => closeModal()}
        />
      </div>
      <div className="mobile--filter--container--body">
        <div className="mobile--filter--container--body-category">
          <div className="filter" id={id + "MenuItemPrice"}>
            <div
              className="filter--menu--item"
              onClick={(e) => {
                e.stopPropagation();
                const filter = document.getElementById(id + "MenuItemPrice");
                if (filter.classList.contains("active")) {
                  filter.classList.remove("active");
                } else {
                  filter.classList.add("active");
                }
              }}
            >
              <div className="filter--menu--item-content">
                <span>Giá tiền</span>
              </div>
              <div className="icon">
                <ICChevronDown width={24} height={24} />
              </div>
            </div>
            <div className="filter--sub">
              <InputDualRange
                id={id + "Price"}
                minStep={minStep}
                minRange={minRange}
                maxRange={maxRange}
                minValue={tempMin ?? minRange}
                maxValue={tempMax ?? maxRange}
                setMinValue={setTempMin}
                setMaxValue={setTempMax}
                minLabel={formatToVND(tempMin ?? minRange)}
                maxLabel={formatToVND(tempMax ?? maxRange)}
              />

              <div className="container--label">
                <ChipSuggest32
                  isSelected={tempMin === "0" && tempMax === "5000000"}
                  title={"Dưới 5 triệu"}
                  onClick={() => {
                    setTempMin("0");
                    setTempMax("5000000");
                  }}
                />
                <ChipSuggest32
                  isSelected={tempMin === "5000000" && tempMax === "10000000"}
                  title={"5 đến 10 triệu"}
                  onClick={() => {
                    setTempMin("5000000");
                    setTempMax("10000000");
                  }}
                />
                <ChipSuggest32
                  isSelected={tempMin === "10000000" && tempMax === "15000000"}
                  title={"10 đến 15 triệu"}
                  onClick={() => {
                    setTempMin("10000000");
                    setTempMax("15000000");
                  }}
                />
                <ChipSuggest32
                  isSelected={tempMin === "15000000" && tempMax === "20000000"}
                  title={"15 đến 20 triệu"}
                  onClick={() => {
                    setTempMin("15000000");
                    setTempMax("20000000");
                  }}
                />
                <ChipSuggest32
                  isSelected={tempMin === "20000000" && tempMax === "30000000"}
                  title={"20 đến 30 triệu"}
                  onClick={() => {
                    setTempMin("20000000");
                    setTempMax("30000000");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="filter" id={id + "MenuItemMaterial"}>
            <div
              className="filter--menu--item"
              onClick={(e) => {
                e.stopPropagation();
                const filter = document.getElementById(id + "MenuItemMaterial");
                if (filter.classList.contains("active")) {
                  filter.classList.remove("active");
                } else {
                  filter.classList.add("active");
                }
              }}
            >
              <div className="filter--menu--item-content">
                <span>Vật liệu</span>
              </div>
              <ICChevronDown width={24} height={24} />
            </div>
            <div className="filter--sub">
              <div className="container--material">
                {dataProductCategory?.materialFilter?.map((material, i) => (
                  <ChipSuggest32
                    key={i}
                    title={material?.title}
                    onClick={() => {
                      const newMaterial = tempMaterial;
                      const index = newMaterial?.findIndex(
                        (id) => id === material._id
                      );
                      if (index > -1) {
                        newMaterial.splice(index, 1);
                      } else newMaterial.push(material?._id);
                      setTempMaterial([...newMaterial]);
                    }}
                    isSelected={tempMaterial.includes(material?._id)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="filter" id={id + "MenuItemColor"}>
            <div
              className="filter--menu--item"
              onClick={(e) => {
                e.stopPropagation();
                const filter = document.getElementById(id + "MenuItemColor");
                if (filter.classList.contains("active")) {
                  filter.classList.remove("active");
                } else {
                  filter.classList.add("active");
                }
              }}
            >
              <div className="filter--menu--item-content">
                <span>Màu sắc</span>
              </div>
              <ICChevronDown width={24} height={24} />
            </div>
            <div className="filter--sub">
              <div className="container--color">
                {dataProductCategory?.similarColorFilter?.map((color, i) => (
                  <ChipSuggestIcon32
                    isSelected={tempColor.includes(color?._id)}
                    key={i}
                    title={color?.title}
                    item={
                      <img
                        src={color?.icon}
                        alt="filter color"
                        style={{ verticalAlign: "top" }}
                      />
                    }
                    onClick={(e) => {
                      const newColor = tempColor;
                      const index = newColor?.findIndex(
                        (id) => id === color._id
                      );
                      if (index > -1) {
                        newColor.splice(index, 1);
                      } else newColor.push(color?._id);
                      setTempColor([...newColor]);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="filter" id={id + "MenuItemStyle"}>
            <div
              className="filter--menu--item"
              onClick={(e) => {
                e.stopPropagation();
                const filter = document.getElementById(id + "MenuItemStyle");
                if (filter.classList.contains("active")) {
                  filter.classList.remove("active");
                } else {
                  filter.classList.add("active");
                }
              }}
            >
              <div className="filter--menu--item-content">
                <span>Phong cách</span>
              </div>
              <ICChevronDown width={24} height={24} />
            </div>
            <div className="filter--sub">
              <div className="container--material">
                {dataProductCategory?.styleFilter?.map((style, i) => (
                  <ChipSuggest32
                    key={i}
                    title={style?.title}
                    onClick={() => {
                      const newStyle = tempStyle;
                      const index = newStyle?.findIndex(
                        (id) => id === style._id
                      );
                      if (index > -1) {
                        newStyle.splice(index, 1);
                      } else newStyle.push(style?._id);
                      setTempMaterial([...newStyle]);
                    }}
                    isSelected={tempStyle?.includes(style?._id)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="filter" id={id + "MenuItemShape"}>
            <div
              className="filter--menu--item"
              onClick={(e) => {
                e.stopPropagation();
                const filter = document.getElementById(id + "MenuItemShape");
                if (filter.classList.contains("active")) {
                  filter.classList.remove("active");
                } else {
                  filter.classList.add("active");
                }
              }}
            >
              <div className="filter--menu--item-content">
                <span>Kiểu dáng</span>
              </div>
              <ICChevronDown width={24} height={24} />
            </div>
            <div className="filter--sub">
              <div className="container--material">
                {dataProductCategory?.shapeFilter?.map((shape, i) => (
                  <ChipSuggest32
                    key={i}
                    title={shape?.title}
                    onClick={() => {
                      const newShape = tempShape;
                      const index = newShape?.findIndex(
                        (id) => id === shape._id
                      );
                      if (index > -1) {
                        newShape.splice(index, 1);
                      } else newShape.push(shape?._id);
                      setTempMaterial([...newShape]);
                    }}
                    isSelected={tempShape?.includes(shape?._id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile--filter--container--footer">
        <ButtonOutlineMedium
          title={"Hủy bỏ"}
          type={"button"}
          onClick={() => {
            closeModal();
            setFromFilter(undefined);
            setToFilter(undefined);
            setColorFilter(undefined);
            setMaterialFilter(undefined);
            setStyleFilter(undefined);
            setShapeFilter(undefined);
          }}
        />
        <ButtonSolidMedium
          title={"Xác nhận"}
          onClick={() => {
            setFromFilter(tempMin);
            setToFilter(tempMax);
            setColorFilter(tempColor);
            setMaterialFilter(tempMaterial);
            setStyleFilter(tempStyle);
            setShapeFilter(tempShape)
            closeModal();

          }}
          type={"button"}
        />
      </div>
    </div>
  );
};

export default MobileFilter;
