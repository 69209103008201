import callApi from "../../utils/callApi";
import { OrderPath } from "../../utils/enum";
import { updateOrder } from "../reducers/order.reducers";

export const createOderAction = ({code, amount, shippingFee, discount, merchTxnRef, address, phone, items, type, name_owner}) => {
  return async (dispatch) => {
    try {
      const res = await callApi(OrderPath.ORDER, "POST", {
        code,
        amount,
        shipping_fee: shippingFee,
        discount,
        merch_txn_ref: merchTxnRef,
        address,
        phone,
        items,
        type,
        name_owner
      });
      dispatch(updateOrder(res.data.data))
      return res;
    } catch (err) {
      throw err;
    }
  };
};

export const updateOderAction =  ({code, status, amount_paid}) => {
  return async (dispatch) => {
    try {
      return await callApi(OrderPath.ORDER_STATUS, "POST",null, null, {order_id:code, status, amount_paid});
    } catch (err) {
      throw err;
    }
  };
    
};
export const getOrderAction =  ({id}) => {
  return async (dispatch) => {
    try {
      const res =  await callApi(OrderPath.ORDER+"/"+id, "GET",null, null);
      dispatch(updateOrder(res.data.data))
      return res
    } catch (err) {
      throw err;
    }
  };
    
};
