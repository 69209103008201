import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import callApi from "../../utils/callApi";
import { formatToVND } from "../../utils/currency";
import "./ModalCart.scss";

export default function ModalCart({ close }) {
  const navigate = useNavigate();

  const { cartLocal } = useSelector((state) => state.cartLocal);

  const [cartData, setCartData] = useState([]);

  const convertData = (data) => {
    if (data?.product) {
      return [
        {
          price_id: data.product._id,
          quantity: data.quantity,
        },
      ];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const path = "carts";

      const dtBody = convertData(cartLocal.lastItem);

      try {
        const res = await callApi(path, "POST", dtBody);

        if (res?.data?.data) {
          setCartData(res.data.data[0]);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [cartLocal.lastItem]);

  return (
    <main className="modal-cart">
      <div className="modal-cart__close" onClick={close}></div>

      <div className="modal-cart__main">
        <div className="modal-cart__main__header">
          <h4 className="modal-cart__main__header__title">
            Đã thêm vào giỏ hàng
          </h4>

          <button
            className="modal-cart__main__header__close"
            onClick={() => {
              close();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.39994 18.6542L5.34619 17.6004L10.9462 12.0004L5.34619 6.40043L6.39994 5.34668L11.9999 10.9467L17.5999 5.34668L18.6537 6.40043L13.0537 12.0004L18.6537 17.6004L17.5999 18.6542L11.9999 13.0542L6.39994 18.6542Z"
                fill="#121212"
              />
            </svg>
          </button>
        </div>

        <div className="modal-cart__main__body">
          <div className="card-cart">
            <img
              className="card-cart__image"
              src={
                cartLocal?.lastItem?.product?.images &&
                cartLocal?.lastItem?.product?.images[0]
              }
              alt="product"
              onContextMenu={(e) => e.preventDefault()}
            />

            <div className="card-cart__info">
              <h5 className="card-cart__info__name">
                {cartData?.product_name}
              </h5>

              <div className="card-cart__info__detail">
                {cartData?.features?.map((fItem, fIdx) => {
                  return (
                    <p key={fIdx} className="card-cart__info__detail__text">
                      {fItem}
                    </p>
                  );
                })}
              </div>

              <div className="card-cart__info__price">
                {cartData?.price_after !== cartData?.price_original && (
                  <span className="card-cart__info__price__org">
                    {formatToVND(cartData?.price_original)}
                  </span>
                )}

                <span
                  className={`card-cart__info__price__sale ${
                    cartData?.price_after === cartData?.price_original &&
                    "black"
                  }`}
                >
                  {formatToVND(cartData?.price_after)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-cart__main__footer col-12">
          <button
            className="modal-cart__main__footer__submit"
            onClick={() => {
              close();
              navigate("/gio-hang");
            }}
          >
            Tiến hành đặt hàng
          </button>
        </div>
      </div>
    </main>
  );
}
