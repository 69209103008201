import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
};

export const MomoPayment = createSlice({
  name: "order",
  initialState,
  reducers: {
   redirectMomo: (state, action) => {
    window.location.href = action.payload;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const { redirectMomo} =
MomoPayment.actions;

export default MomoPayment.reducer;
