/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import PaginationBasic from "../../components/Pagination/PaginationBasic";
import "./BlogPage.scss";
import { useEffect, useState } from "react";
import { getBlogsAction, getDataSection } from "../../store/actions/blog.action";
import BlogCard from "../../components/BlogCard/BlogCard";

const BlogPage = () => {
    const dispatch = useDispatch();
    const { dataBlogPage, section, blogs, totalBlogs } = useSelector((state) => state.dataBlogPage);
    const [limit, setLimit] = useState(6);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        dispatch(getDataSection());
    }, []);

    useEffect(()=>{
        dispatch(getBlogsAction({limit, offset}));
    },[limit, offset])

    return (<div className="blog--page">
        {section && dataBlogPage[section] && dataBlogPage[section] && <section className={section}>
            <img src={dataBlogPage[section].attachments[0]} alt="" />
            <div className="layer-blur"></div>
            <div className={section + "--content"}>
                <h1 className={section + "--content--title"} dangerouslySetInnerHTML={{
                    __html: dataBlogPage[section].title || "",
                }}></h1>
                <h3 className={section + "--content--content"} dangerouslySetInnerHTML={{
                    __html: dataBlogPage[section].content || "",
                }}></h3>
            </div>
        </section>}

        <section className="blog--page--blog--group">
            {
                blogs.map(blog=><div className="blog--list--container-item" key={blog?._id}>
                    <BlogCard blog={blog}/>
                </div>)
            }
        </section>
        <section className="blog--page--pagination">
            <PaginationBasic total={totalBlogs/limit} limit={limit} setLimit={setLimit} offset={offset} setOffset={setOffset} id="pagination" />
        </section>    </div>);
}

export default BlogPage;