import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartLocal: {
    listProduct:
      JSON.parse(localStorage.getItem("cartLocal"))?.listProduct || [],
    lastItem: null,
  },
};

const updateLocalStorage = (listProduct) => {
  localStorage.setItem("cartLocal", JSON.stringify({ listProduct }));
};

export const CartLocalReducer = createSlice({
  name: "cartLocal",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newProduct = action.payload;
      const cartItem = {
        product: newProduct,
        quantity: 1,
      };

      const index = state.cartLocal.listProduct.findIndex(
        (cItem) => cItem.product._id === newProduct._id
      );

      if (index === -1) {
        state.cartLocal.listProduct.push(cartItem);
      } else {
        state.cartLocal.listProduct[index].quantity =
          state.cartLocal.listProduct[index].quantity + 1;
      }

      state.cartLocal.lastItem = cartItem;

      updateLocalStorage(state.cartLocal.listProduct);
    },

    increaseQuantity: (state, action) => {
      const productId = action.payload;
      const index = state.cartLocal.listProduct.findIndex(
        (cItem) => cItem.product._id === productId
      );

      if (index !== -1) {
        state.cartLocal.listProduct[index].quantity =
          state.cartLocal.listProduct[index].quantity + 1;
      }

      console.log(action.payload);
      console.log(index);

      updateLocalStorage(state.cartLocal.listProduct);
    },

    decreaseQuantity: (state, action) => {
      const productId = action.payload;
      const index = state.cartLocal.listProduct.findIndex(
        (cItem) => cItem.product._id === productId
      );

      if (index !== -1) {
        if (state.cartLocal.listProduct[index].quantity > 1) {
          state.cartLocal.listProduct[index].quantity =
            state.cartLocal.listProduct[index].quantity - 1;
        }
      }

      updateLocalStorage(state.cartLocal.listProduct);
    },

    removeInCart: (state, action) => {
      const productId = action.payload;
      if(productId){
        state.cartLocal.listProduct.splice(
          state.cartLocal.listProduct.findIndex(
            (cItem) => cItem.product._id === productId
          ),
          1
        );
        updateLocalStorage(state.cartLocal.listProduct);
      }else{
        localStorage.removeItem("cartLocal");
      }

    },
  },
});

export const { addToCart, increaseQuantity, decreaseQuantity, removeInCart } =
  CartLocalReducer.actions;

export default CartLocalReducer.reducer;
