import { Tabs } from "antd";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Navigation, Pagination, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ICBookmark from "../../assets/icons/ICBookmark";
import ICExpand from "../../assets/icons/ICExpand";
import Loading from "../../components/Loading/Loading";
import ModalCustom from "../../components/Modal/ModalCustom";
import ModalViewFull from "../../components/Modal/ModalViewFull";
import SwiperScroll from "../../components/SwiperScroll/SwiperScroll";
import TagPrice from "../../components/Tag/TagPrice";
import {
  getFeatureDetailAction,
  removeFeatureDetailAction,
} from "../../store/actions/featureDetail.action";
import { getDataSection } from "../../store/actions/homePage.action";
import {
  getProductDetailAction,
  removeProductDetailAction,
} from "../../store/actions/productDetail.action";
import { getSimilarSofaAction } from "../../store/actions/similarSofa.action";
import { addToCart } from "../../store/reducers/cartLocal.reducers";
import { formatToVND } from "../../utils/currency";
import { SectionPath } from "../../utils/enum";
import "./ProductDetail.scss";
import { useParams } from "react-router-dom";

export default function ProductDetail() {
  const dispatch = useDispatch();

  const swiperRef = useRef(null);

  const { dataHomePage } = useSelector((state) => state.dataHomePage);

  const { _id } = useParams();

  const { productDetail } = useSelector((state) => state.productDetail);
  const { featureDetail } = useSelector((state) => state.featureDetail);
  const { similarSofa } = useSelector((state) => state.similarSofa);

  const [current, setCurrent] = useState(0);
  const [loadedSlides, setLoadedSlides] = useState([0]);

  const [currentSlide, setCurrentSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState(0);
  const [featureData, setFeatureData] = useState(null);
  const [dataCustom, setDataCustom] = useState(null);
  const [dataCustomTemp, setDataCustomTemp] = useState(null);
  const [blogItems, setBlogItems] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 577);
  const [showCustom, setShowCustom] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const [showBlog, setShowBlog] = useState(false);

  const [loading, setLoading] = useState(false);

  const closeCustom = () => {
    setShowCustom(false);
  };

  const closeFull = () => {
    setShowFull(false);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 576);
  };

  const handleSlideChange = (swiper) => {
    const { realIndex } = swiper;
    setCurrent(realIndex);
    setLoadedSlides((prev) =>
      prev.includes(realIndex) ? prev : [...prev, realIndex]
    );
  };

  useEffect(() => {
    dispatch(getProductDetailAction(_id));

    dispatch(getDataSection(SectionPath.PROMOTION_ITEMS));

    window.addEventListener("resize", handleResize);
    setLoading(true);

    return () => {
      dispatch(removeProductDetailAction(_id));
      dispatch(removeFeatureDetailAction(_id));

      window.removeEventListener("resize", handleResize);
    };
  }, [_id]);

  useEffect(() => {
    if (dataCustom && dataCustom.option) {
      const lstFeatureId = Object.values(dataCustom.option).map(
        (item) => item.optionItemId
      );

      if (lstFeatureId?.length !== 0) {
        dispatch(getFeatureDetailAction(lstFeatureId, _id, setLoading));
      }
    } else {
      dispatch(removeFeatureDetailAction(_id));
    }
  }, [dataCustom]);

  useEffect(() => {
    if (dataCustom === null) {
      setDataCustom(dataCustomTemp);
    }
  }, [dataCustom, dataCustomTemp]);

  useEffect(() => {
    document.title = productDetail?.name
      ? `${productDetail?.name} - Là Nhà Decor`
      : "Là Nhà Decor";

    if (productDetail?.categories?.length > 0) {
      const queryString = productDetail?.categories
        ?.map((id) => `&category=${id}`)
        .join("");

      dispatch(getSimilarSofaAction(`products?${queryString}`));
    }

    let newData = { _id: _id, option: {}, price: 0 };

    productDetail?.features?.forEach((fItem) => {
      fItem?.sub_features?.forEach((fSub) => {
        fSub?.sub_features?.forEach((fSubSub) => {
          if (fSubSub.default === true) {
            const newSubFeature = {
              optionId: fSub._id,
              optionName: fSub.title,
              optionItemId: fSubSub._id,
              optionItemName: fSubSub.title,
              optionItemIcon: fSubSub.icon,
            };

            newData.option[fItem._id] = newSubFeature;
          }
        });
      });
    });

    setDataCustom(newData);
    setDataCustomTemp(newData);

    const convertBlogItem = productDetail?.blogs?.map((bItem, bIdx) => ({
      key: (bIdx + 1).toString(),
      label: bItem.name_tab,
      children: (
        <div
          className="blog__tab-content"
          dangerouslySetInnerHTML={{
            __html: bItem.content,
          }}
        />
      ),
    }));

    setBlogItems(convertBlogItem);
  }, [productDetail]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;

      setCurrentSlide(swiper.activeIndex + 1);
      setTotalSlides(featureDetail?.images?.length || 0);

      console.log(swiper);
    }
  }, [featureDetail]);

  useEffect(() => {
    // if (!isMobile) {
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    if (showCustom) {
      document.body.classList.add("no-scroll");
      document.body.style.paddingRight = `${scrollBarWidth}px`;

      if (!isMobile) {
        window.scrollTo(0, 0);
      }
    } else {
      document.body.classList.remove("no-scroll");
      document.body.style.paddingRight = "0";
    }
    // }

    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.paddingRight = "0";
    };
  }, [showCustom, isMobile]);

  return (
    <main className="product-detail">
      <section className="product-intro">
        {!isMobile && (
          <div className="product-intro__image">
            {!featureDetail?.images?.length > 0 && (
              <div className="loading-container">
                <Loading />
              </div>
            )}

            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              spaceBetween={10}
              navigation={true}
              speed={1000}
              pagination={{
                type: "bullets",
                dynamicBullets: true,
                dynamicMainBullets: 5,
                clickable: true,
              }}
              virtual={true}
              modules={[Navigation, Pagination, Virtual]}
              onSlideChange={(swiper) => {
                setCurrentSlide(swiper.activeIndex + 1);
                handleSlideChange(swiper);
              }}
              lazy={{
                loadPrevNext: false, // Không tải ảnh trước/sau
                loadOnTransitionStart: true, // Chỉ tải khi bắt đầu chuyển slide
              }}
              style={{ minHeight: "70vh" }}
            >
              {featureDetail?.images?.map((imgUrl, idx) => {
                return (
                  <SwiperSlide
                    key={idx}
                    virtualIndex={idx}
                    style={{ overflow: "hidden" }}
                  >
                    {loadedSlides.includes(idx) ? (
                      <>
                        <img
                          src={imgUrl}
                          alt="feature item"
                          onContextMenu={(e) => e.preventDefault()}
                        />

                        {loading && (
                          <div className="loading-container">
                            <Loading />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="loading-container">
                        <Loading />
                      </div>
                    )}
                    {/* <img
                      src={imgUrl}
                      alt="feature item"
                      onContextMenu={(e) => e.preventDefault()}
                    />

                    {loading && (
                      <div className="loading-container">
                        <Loading />
                      </div>
                    )} */}
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {featureDetail?.images?.length > 0 && (
              <div>
                <div className="product-intro__image__button">
                  <button
                    className="btn-expand"
                    onClick={() => {
                      setShowFull(true);
                    }}
                  >
                    <ICExpand width={24} height={24} />
                  </button>
                </div>

                <span className="product-intro__image__number">
                  {currentSlide}/{totalSlides}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="product-intro__content">
          <div className="d-flex flex-column">
            {productDetail?.category ? (
              <h6
                className="product-intro__content__type mb-2"
                dangerouslySetInnerHTML={{
                  __html: productDetail?.category,
                }}
              />
            ) : (
              <Skeleton />
            )}

            {productDetail?.name ? (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <h2
                  className="product-intro__content__name"
                  dangerouslySetInnerHTML={{
                    __html: productDetail?.name,
                  }}
                />

                <ICBookmark width={24} height={24} />
              </div>
            ) : (
              <Skeleton height={36} />
            )}
          </div>

          {productDetail?.description ? (
            <p
              className="product-intro__content__desc"
              dangerouslySetInnerHTML={{
                __html: productDetail?.description,
              }}
            />
          ) : (
            <Skeleton count={8} />
          )}

          {isMobile && (
            <div className="product-intro__image">
              {!featureDetail?.images?.length > 0 && (
                <div style={{ minHeight: "200px" }}>
                  <div className="loading-container">
                    <Loading />
                  </div>
                </div>
              )}

              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                spaceBetween={10}
                navigation={true}
                speed={1000}
                pagination={{
                  type: "bullets",
                  dynamicBullets: true,
                  dynamicMainBullets: 3,
                  clickable: true,
                }}
                virtual={true}
                modules={[Navigation, Pagination, Virtual]}
                onSlideChange={(swiper) =>
                  setCurrentSlide(swiper.activeIndex + 1)
                }
              >
                {featureDetail?.images?.map((imgUrl, idx) => {
                  return (
                    <SwiperSlide key={idx} virtualIndex={idx}>
                      <img
                        src={imgUrl}
                        alt="feature item"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <div className="product-intro__image__button">
                <button
                  className="btn-expand"
                  onClick={() => {
                    setShowFull(true);
                  }}
                >
                  <ICExpand width={24} height={24} />
                </button>
              </div>

              <span className="product-intro__image__number">
                {currentSlide}/{totalSlides}
              </span>
            </div>
          )}

          <div
            className="product-intro__content__custom d-flex flex-column"
            style={{ gap: 16 }}
          >
            {productDetail?.description ? (
              <h6 className="product-intro__content__custom__header">
                Tùy chọn
              </h6>
            ) : (
              <Skeleton />
            )}

            {dataCustom &&
              dataCustom?.option &&
              productDetail?.features?.map((fItem) => {
                const subFeature = fItem?.sub_features?.find(
                  (fSub) =>
                    dataCustom &&
                    dataCustom.option[fItem._id] &&
                    dataCustom.option[fItem._id]?.optionId === fSub._id
                );

                return (
                  <div
                    key={fItem._id}
                    className="product-intro__content__custom__body"
                    onClick={() => {
                      setShowCustom(true);
                      setFeatureData(fItem);
                    }}
                  >
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="custom-body__title">{fItem.title}</p>

                      {/* {fItem?.sub_features?.sub_features?.length > 1 && (
                        <span className="custom-body__number">
                          +{fItem.sub_features.sub_features.length - 1}
                        </span>
                      )} */}
                      {subFeature?.sub_features?.length > 1 && (
                        <span className="custom-body__number">
                          +{subFeature.sub_features.length - 1}
                        </span>
                      )}
                    </div>

                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div className="d-flex flex-row">
                        <img
                          className="custom-body__image"
                          src={
                            dataCustom &&
                            dataCustom.option &&
                            dataCustom.option[fItem._id] &&
                            dataCustom.option[fItem._id]?.optionItemIcon
                          }
                          alt="option icon"
                          onContextMenu={(e) => e.preventDefault()}
                        />

                        <div className="d-flex flex-column">
                          <p className="custom-body__name">
                            {dataCustom &&
                              dataCustom.option &&
                              dataCustom.option[fItem._id] &&
                              dataCustom.option[fItem._id]?.optionName}
                          </p>
                          <p className="custom-body__color">
                            {fItem?.type?.toLowerCase() === "layout"
                              ? featureDetail?.size_info
                              : dataCustom &&
                                dataCustom.option &&
                                dataCustom.option[fItem._id] &&
                                dataCustom.option[fItem._id]?.optionItemName}
                          </p>
                        </div>
                      </div>

                      <button className="btn-underline hover-underline-animation">
                        Thay đổi
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="product-intro__content__price">
            {featureDetail?.price_after !== featureDetail?.price_original && (
              <span className="price-original">
                {formatToVND(featureDetail?.price_original)}
              </span>
            )}

            <div
              className="d-flex flex-row justify-content-start align-items-center"
              style={{ gap: 8 }}
            >
              <span className="price-sale">
                {formatToVND(featureDetail?.price_after)}
              </span>

              {featureDetail?.price_after !== featureDetail?.price_original && (
                <TagPrice title={"Giá mới"} absoluteTopLeft={false} />
              )}
            </div>
          </div>

          {productDetail?.description && (
            <button
              className="btn-main w-100 justify-content-center"
              onClick={() => {
                dispatch(addToCart(featureDetail));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M6.80775 21.5C6.30258 21.5 5.875 21.325 5.525 20.975C5.175 20.625 5 20.1974 5 19.6923V8.30775C5 7.80258 5.175 7.375 5.525 7.025C5.875 6.675 6.30258 6.5 6.80775 6.5H8.75V6.25C8.75 5.21417 9.116 4.33017 9.848 3.598C10.5802 2.866 11.4642 2.5 12.5 2.5C13.5358 2.5 14.4198 2.866 15.152 3.598C15.884 4.33017 16.25 5.21417 16.25 6.25V6.5H18.1923C18.6974 6.5 19.125 6.675 19.475 7.025C19.825 7.375 20 7.80258 20 8.30775V19.6923C20 20.1974 19.825 20.625 19.475 20.975C19.125 21.325 18.6974 21.5 18.1923 21.5H6.80775ZM6.80775 20H18.1923C18.2692 20 18.3398 19.9679 18.4038 19.9038C18.4679 19.8398 18.5 19.7693 18.5 19.6923V8.30775C18.5 8.23075 18.4679 8.16025 18.4038 8.09625C18.3398 8.03208 18.2692 8 18.1923 8H16.25V10.25C16.25 10.4628 16.1782 10.641 16.0345 10.7845C15.891 10.9282 15.7128 11 15.5 11C15.2872 11 15.109 10.9282 14.9655 10.7845C14.8218 10.641 14.75 10.4628 14.75 10.25V8H10.25V10.25C10.25 10.4628 10.1782 10.641 10.0345 10.7845C9.891 10.9282 9.71283 11 9.5 11C9.28717 11 9.109 10.9282 8.9655 10.7845C8.82183 10.641 8.75 10.4628 8.75 10.25V8H6.80775C6.73075 8 6.66025 8.03208 6.59625 8.09625C6.53208 8.16025 6.5 8.23075 6.5 8.30775V19.6923C6.5 19.7693 6.53208 19.8398 6.59625 19.9038C6.66025 19.9679 6.73075 20 6.80775 20ZM10.25 6.5H14.75V6.25C14.75 5.62317 14.5317 5.09142 14.0953 4.65475C13.6588 4.21825 13.127 4 12.5 4C11.873 4 11.3412 4.21825 10.9048 4.65475C10.4683 5.09142 10.25 5.62317 10.25 6.25V6.5Z"
                  fill="#121212"
                />
              </svg>
              Thêm vào giỏ hàng
            </button>
          )}
        </div>
      </section>

      <section
        className={`modal-container ${
          showCustom ? "modal-container-show" : ""
        }`}
      >
        <div
          className={`modal-right ${
            showCustom ? "modal-right-in" : "modal-right-out"
          }`}
        >
          <ModalCustom
            loading={loading}
            featureDetail={featureDetail}
            featureData={featureData}
            dataCustom={dataCustom}
            setDataCustom={setDataCustom}
            dataCustomTemp={dataCustomTemp}
            setDataCustomTemp={setDataCustomTemp}
            close={closeCustom}
          />
        </div>
      </section>

      <section
        className={`modal-container ${showFull ? "modal-container-show" : ""}`}
      >
        <div
          className={`modal-fadeup ${!showFull ? "modal-fadeup-leave" : ""}`}
        >
          <ModalViewFull
            featureDetail={featureDetail}
            isMobile={isMobile}
            close={closeFull}
          />
        </div>
      </section>

      <section className="promo">
        <div className="promo-container">
          {dataHomePage?.promotion__items?.items?.map((pItem) => {
            return (
              <div key={pItem._id} className="promo-container__card">
                <img
                  src={pItem.icon_title}
                  alt={pItem.title}
                  onContextMenu={(e) => e.preventDefault()}
                />

                <h5
                  className="promo-container__card__title"
                  dangerouslySetInnerHTML={{
                    __html: pItem.title,
                  }}
                />

                <p
                  className="promo-container__card__content"
                  dangerouslySetInnerHTML={{
                    __html: pItem.sub_title,
                  }}
                />
              </div>
            );
          })}
        </div>
      </section>

      <section className="key-details">
        <div className="key-details__image">
          <img
            src={productDetail?.info_picture}
            alt="info"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>

        <div
          className="key-details__content"
          dangerouslySetInnerHTML={{
            __html: productDetail?.info,
          }}
        />
      </section>

      <section className={`blog ${showBlog ? "blog-full" : ""}`}>
        <div className="blog__tabs">
          <Tabs defaultActiveKey="1" items={blogItems} onChange={onChange} />
        </div>

        {!showBlog && (
          <button
            className="btn-readMore"
            onClick={() => {
              setShowBlog(true);
            }}
          >
            Xem thêm
          </button>
        )}
      </section>

      <SwiperScroll title={"Dành cho bạn"} data={similarSofa} />
    </main>
  );
}
