import { useLottie } from "lottie-react";
import animationData from "../../assets/lottie/Loading.json";

const Loading = ({ height }) => {
  const options = {
    animationData: animationData,
    loop: true,
  };

  const { View } = useLottie(options, { height: height ?? 64 });

  return <div style={{ width: "100%", justifyContent: "center", position:"relative" }}>{View}</div>;
};

export default Loading;
