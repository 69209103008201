import { configureStore } from "@reduxjs/toolkit";

import UserReducer from "./reducers/user.reducers";
import HomePageReducer from "./reducers/homePage.reducers";
import ElectricSofaReducer from "./reducers/electricSofa.reducers";
import MechanicalSofaReducer from "./reducers/mechanicalSofa.reducers";
import SimilarSofaReducer from "./reducers/similarSofa.reducers";
import ProductDetailReducer from "./reducers/productDetail.reducers";
import FeatureDetailReducer from "./reducers/featureDetail.reducers";
import ProductCategoryReducer from "./reducers/productCategory.reducers";
import CartLocalReducer from "./reducers/cartLocal.reducers";
import UserDetailLocal from "./reducers/userDetailLocal.reducers";
import Provinces from "./reducers/provinces.reducers";
import RecentlyProduct from "./reducers/recentlyProduct.reducers";
import MomoPayment  from "./reducers/momo.reducers";
import OrderReducer from "./reducers/order.reducers";
import aboutUsReducer from "./reducers/aboutUs.reducer";
import policyReducer from "./reducers/policy.reducer";
import AddressReducer from "./reducers/address.reducer";
import BlogPageReducer from "./reducers/blogPage.reducers";
import FAQReducer from "./reducers/faq.reducers";

export default configureStore({
  reducer: {
    user: UserReducer,
    dataHomePage: HomePageReducer,
    dataBlogPage: BlogPageReducer,
    electricSofa: ElectricSofaReducer,
    mechanicalSofa: MechanicalSofaReducer,
    similarSofa: SimilarSofaReducer,
    productDetail: ProductDetailReducer,
    featureDetail: FeatureDetailReducer,
    dataProductCategory: ProductCategoryReducer,
    cartLocal: CartLocalReducer,
    userDetailLocal: UserDetailLocal,
    dataProvinces: Provinces,
    recentlyProduct: RecentlyProduct,
    momoPayment: MomoPayment,
    order: OrderReducer,
    aboutUs: aboutUsReducer,
    policy: policyReducer,
    address: AddressReducer,
    faq: FAQReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
