import callApi from "../../utils/callApi";
import { setDataSection, setLoading, setError } from "../reducers/address.reducer";

export const getDataSection = (path, sectionName) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true)); // Start loading
      const res = await callApi(path, "GET", null);

      // Update section data
      dispatch(setDataSection({ section: sectionName, ...res.data.data }));
      dispatch(setLoading(false)); // Stop loading
    } catch (err) {
      dispatch(setError(err.message));
      dispatch(setLoading(false));
    }
  };
};
