import callApi from "../../utils/callApi";
import { Blog, SectionPath } from "../../utils/enum";
import { setBlog, setBlogs, setDataBlogPage } from "../reducers/blogPage.reducers";

export const getDataSection = () => {
    return async (dispatch) => {
      try {
        const res = await callApi(SectionPath.HERO_BLOG, "GET", null);
  
        await dispatch(setDataBlogPage(res.data.data));
      } catch (err) {
        console.log(err);
      }
    };
  };

export const getBlogsAction = () => {
  return async (dispatch) => {
    try{
      const res = await callApi(Blog.BLOGS, "GET", null);
      await dispatch(setBlogs(res.data))
    } catch(err){
      console.log(err);
    }
  }
}

export const getBlogAction = (id) => {
  return async (dispatch) => {
    try{
      const res = await callApi(Blog.BLOG+`/${id}`, "GET", null);
      await dispatch(setBlog(res.data.data))
    } catch(err){
      console.log(err);
    }
  }
}